import { CartService } from './../services/cart.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { LoginComponent } from '../login/login.component';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { NavbarComponent } from 'angular-bootstrap-md';
import { ProductsService } from '../services/products.service';

@Component({
  selector: 'app-barra',
  templateUrl: './barra.component.html',
  styleUrls: ['./barra.component.scss'],
})
export class BarraComponent implements OnInit {
  logueado = false;
  @ViewChild('navbar', { static: true }) navbar: NavbarComponent;
  generales: any;
  constructor(
    private dialog: MatDialog,
    public authSrv: AuthService,
    private router: Router,
    public cartSrv: CartService,
    public productSrv: ProductsService
  ) {}

  ngOnInit() {
    this.authSrv.reauth(localStorage.getItem('token')).subscribe((data) => {
      this.authSrv.setAuth();
      this.authSrv.setToken(localStorage.getItem('token'));
      this.logueado = this.authSrv.isAuth();
      this.authSrv.setUserInfo(data.user);
      this.cartSrv.updateCartNumber(this.authSrv.userInfo.id);
    });
    this.getGenerales();
  }

  Login() {
    this.navbar.hide();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '40vw';
    dialogConfig.panelClass = 'loginModal';
    dialogConfig.closeOnNavigation = true;
    const dialogRef = this.dialog.open(LoginComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.authSrv.reauth(localStorage.getItem('token')).subscribe((data) => {
        this.authSrv.setAuth();
        this.authSrv.setToken(localStorage.getItem('token'));
        this.logueado = this.authSrv.isAuth();
        this.authSrv.setUserInfo(data.user);
        this.cartSrv.updateCartNumber(this.authSrv.userInfo.id);
        this.router.onSameUrlNavigation = 'reload';
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        switch (this.router.url) {
          case '/register':
            this.router.navigate(['/perfil']);
            break;
          default:
            this.router.navigate(['/']);
            break;
        }
      });
    });
  }

  getGenerales() {
    this.productSrv.getGenerales().subscribe(
      (data) => {
        this.generales = data.data;
      },
      (error) => {}
    );
  }

  cerrarSesion() {
    this.navbar.hide();
    this.authSrv.logout();
    this.logueado = this.authSrv.isAuth();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.cartSrv.updateCartNumber(0);
    switch (this.router.url) {
      case '/perfil':
        this.router.navigate(['/']);
        break;
      default:
        this.router.navigate([this.router.url]);
        break;
    }
  }

  irCatalogo(marca: number) {
    this.esconderBarra();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/catalogo/linea', { marca: marca }]);
  }

  irCatalogoLinea() {
    this.esconderBarra();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/catalogo/linea']);
  }

  irCatalogoOutlet() {
    this.esconderBarra();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/catalogo/outlet']);
  }

  esconderBarra() {
    this.navbar.hide();
  }
}
