import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { productResponseModel, marcaModel } from '../models/products.model';
import { ProductsService } from '../services/products.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { generalModel } from '../models/general.model';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss'],
})
export class ProductosComponent implements OnInit {
  productReponse: productResponseModel[] = [];

  logueado: boolean = false;
  marcaSelect: number = 0;

  listaMarcas: marcaModel[] = [];

  outlet: boolean = false;
  generales: generalModel;

  constructor(
    public api: ApiService,
    private productSrv: ProductsService,
    private dialog: MatDialog,
    private authSrv: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getGenerales();
    if (this.route.snapshot.paramMap.get('tipo') == 'outlet') {
      this.outlet = true;
    }

    this.getMarcas();
    if (this.route.snapshot.paramMap.get('marca')) {
      console.log('se paso una marca');
      this.marcaSelect = Number(this.route.snapshot.paramMap.get('marca'));
      this.getProductosbyMarca();
    } else {
      console.log('no se paso una marca');
      this.marcaSelect = 0;
      this.getProducts();
    }
    this.authSrv.reauth(localStorage.getItem('token')).subscribe((data) => {
      this.authSrv.setAuth();
      this.authSrv.setToken(localStorage.getItem('token'));
      this.logueado = this.authSrv.isAuth();
    });
    console.log(this.authSrv.isAuth());
  }

  getGenerales() {
    this.productSrv.getGenerales().subscribe(
      (data) => {
        this.generales = data.data;
      },
      (error) => {}
    );
  }

  getProducts() {
    this.productSrv.getAllActiveProducts().subscribe(
      (data) => {
        console.log(data);
        this.productReponse = data.data;
        if (this.outlet == true) {
          this.productReponse = this.productReponse.filter((item) => {
            return item.product.outlet == true;
          });
        } else {
          this.productReponse = this.productReponse.filter((item) => {
            return item.product.outlet == false;
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  irProducto(id: number) {
    this.router.navigate(['/product_detalle', { id: id }]);

    /* const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { id: id };
    dialogConfig.minWidth = '50vw';
    this.dialog.open(ProductoDetalleComponent, dialogConfig); */
  }

  getMarcas() {
    this.productSrv.getMarcas().subscribe(
      (data) => {
        this.listaMarcas = data.data.filter((item) => {
          return item.id > 1;
        });
        console.log(data);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getProductosbyMarca() {
    this.productSrv.getProductByMarca(this.marcaSelect).subscribe(
      (data) => {
        this.productReponse = data.data;
        this.productReponse = this.productReponse.filter((item) => {
          return item.product.active == true;
        });
        if (this.outlet == true) {
          this.productReponse = this.productReponse.filter((item) => {
            return item.product.outlet == true;
          });
        } else {
          this.productReponse = this.productReponse.filter((item) => {
            return item.product.outlet == false;
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  cambiarMarca() {
    if (this.marcaSelect == 0) {
      this.getProducts();
    } else {
      this.getProductosbyMarca();
    }
  }
}
