import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss'],
})
export class LoginAdminComponent implements OnInit {
  email: string;
  password: string;
  @ViewChild('alert', { static: true }) alert: ElementRef;

  constructor(private adminSrv: AdminService, private router: Router) {}

  ngOnInit() {
    this.adminSrv
      .reauth(localStorage.getItem('tokenAdmin'))
      .subscribe((data) => {
        this.adminSrv.setAuth();
        this.adminSrv.setToken(localStorage.getItem('tokenAdmin'));
        this.adminSrv.setUserInfo(data.user);
        this.router.onSameUrlNavigation = 'reload';
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/admin/dashboard/linea']);
      });
  }

  loginUser() {
    const loginObj = {
      email: this.email,
      password: this.password,
    };
    this.adminSrv.login(loginObj).subscribe(
      (data) => {
        console.log(data);
        localStorage.setItem('tokenAdmin', data.api_key);
        this.adminSrv.setToken(data.api_key);
        this.adminSrv.setAuth();
        this.router.onSameUrlNavigation = 'reload';
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/admin/dashboard/linea']);
      },
      (error) => {
        console.log(error);
        this.showAlert();
      }
    );
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

  showAlert() {
    this.alert.nativeElement.classList.remove('d-none');
    this.alert.nativeElement.classList.add('show');
  }
}
