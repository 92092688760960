import {
  listaOrdenModel,
  listaOrdenFinalModel,
} from './../../../models/order.model';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  HostListener,
  ViewChild,
} from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from 'angular-bootstrap-md';

@Component({
  selector: 'app-admin-lista-ordenes',
  templateUrl: './admin-lista-ordenes.component.html',
  styleUrls: ['./admin-lista-ordenes.component.scss'],
})
export class AdminListaOrdenesComponent implements OnInit {
  listaOrdenes: listaOrdenModel[] = [];

  listaOrdenesFinal: listaOrdenFinalModel[] = [];

  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true })
  mdbTablePagination: MdbTablePaginationComponent;

  headElements = [
    'id',
    'nombre',
    'email',
    'total',
    'condicion_iva',
    'forma_pago',
    'envio',
    'estado',
    'fecha',
  ];

  searchText: string = '';
  previous: string;

  constructor(
    private adminSrv: AdminService,
    private cdRef: ChangeDetectorRef
  ) {}

  @HostListener('input') oninput() {
    this.searchItems();
  }

  ngOnInit() {
    this.adminSrv.reauth(localStorage.getItem('tokenAdmin')).subscribe(
      (data) => {
        console.log('user', data);
        this.adminSrv.setAuth();
        this.adminSrv.setToken(localStorage.getItem('tokenAdmin'));
        this.adminSrv.setUserInfo(data.user);
        this.getListado();
      },
      (error) => {}
    );
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.listaOrdenesFinal = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.listaOrdenesFinal = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }

  getListado() {
    this.adminSrv.listadoOrdenes(this.adminSrv.token).subscribe(
      (data) => {
        data.order.sort((a, b) => {
          return b.id - a.id;
        });
        this.listaOrdenes = data.order;
        this.llenarLista();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  llenarLista() {
    this.listaOrdenesFinal = [];
    for (let index = 0; index < this.listaOrdenes.length; index++) {
      const element = this.listaOrdenes[index];
      let item: listaOrdenFinalModel = {} as listaOrdenFinalModel;

      console.log('entro');

      item.id = element.id;
      item.nombre = element.surname + ', ' + element.name;
      item.email = element.email;
      item.total = element.totalPrice;
      item.condicion_iva = element.iva;
      item.forma_pago = element.payment;
      item.envio = element.shipment;
      item.estado = element.status;
      item.fecha = element.fecha.slice(0, 10);

      this.listaOrdenesFinal.push(item);
    }

    this.mdbTable.setDataSource(this.listaOrdenesFinal);
    this.listaOrdenesFinal = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(8);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  itemLeido(id: number): boolean {
    return this.listaOrdenes.filter((item) => {
      return item.id == id;
    })[0].opened;
  }
}
