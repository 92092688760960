import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {
  productModel,
  marcaModel,
  singleProductResponseModel,
  sectionModel,
  sectionResponseModel,
  subsectionModel,
  productPhotosModel,
} from 'src/app/models/products.model';
import { ProductsService } from 'src/app/services/products.service';
import { AdminService } from 'src/app/services/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-admin-nuevo-producto',
  templateUrl: './admin-nuevo-producto.component.html',
  styleUrls: ['./admin-nuevo-producto.component.scss'],
})
export class AdminNuevoProductoComponent implements OnInit {
  producto: productModel = {} as productModel;
  marcas: marcaModel[] = [];
  data: any;
  secciones: sectionResponseModel[] = [];
  fotosCargadas: productPhotosModel[] = [];
  subseccionesExistentes: subsectionModel[] = [];
  @ViewChild('alert', { static: true }) alert: ElementRef;
  @ViewChild('alertExito', { static: true }) alertExito: ElementRef;
  @ViewChild('alertOrden', { static: true }) alertOrden: ElementRef;
  @ViewChild('alertExitoOrden', { static: true }) alertExitoOrden: ElementRef;

  public uploader: FileUploader = new FileUploader({
    queueLimit: 5,
  });
  public hasBaseDropZoneOver: boolean = false;

  ocultar = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authSrv: AuthService,
    private productSrv: ProductsService,
    private adminSrv: AdminService,
    public api: ApiService,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    this.data = this.route.snapshot.paramMap.get('id');
    this.getMarcas();
    this.getSections();
  }

  getFiles(): any {
    //recupero los items cargados en el input file
    return this.uploader.queue.map((fileItem) => {
      return fileItem._file;
    });
  }

  fileOverBase(ev): void {
    this.hasBaseDropZoneOver = ev;
  }

  eliminarArchivo(item: any) {
    this.uploader.queue.splice(this.uploader.queue.indexOf(item), 1);
  }

  getMarcas() {
    this.productSrv.getMarcas().subscribe(
      (data) => {
        console.log(data);
        this.marcas = data.data;
        if (this.data) {
          this.getProduct();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getProduct() {
    this.productSrv.getProduct(this.data).subscribe(
      (data: singleProductResponseModel) => {
        //Para que aparezca la fecha
        if (data.product.dateArrival) {
          data.product.dateArrival = data.product.dateArrival.slice(0, 10);
        }
        this.producto = data.product;
        if (isNullOrUndefined(this.producto.minOutlet)) {
          this.producto.minOutlet = 1;
        }
        this.subseccionesExistentes = data.subsections;
        this.fotosCargadas = data.photos;
        console.log(data.subsections);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  existeSubseccion(itemb: subsectionModel): boolean {
    let existe = this.subseccionesExistentes.filter((item) => {
      return item.id == itemb.id;
    });
    if (existe[0]) {
      return true;
    } else {
      return false;
    }
  }
  getSections() {
    this.productSrv.getSections().subscribe(
      (data) => {
        console.log(data.data);
        this.secciones = data.data;
      },
      (error) => {}
    );
  }

  subseccionesSeleccionadas(itemb: subsectionModel) {
    let existe = this.subseccionesExistentes.filter((item) => {
      return item.id == itemb.id;
    });

    if (existe[0]) {
      this.subseccionesExistentes.splice(
        this.subseccionesExistentes.indexOf(existe[0]),
        1
      );
    } else {
      this.subseccionesExistentes.push(itemb);
    }
  }

  verArchivos() {
    //console.log(this.getFiles());
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

  showAlert() {
    this.alert.nativeElement.classList.remove('d-none');
    this.alert.nativeElement.classList.add('show');
  }

  closeAlertExito() {
    this.alertExito.nativeElement.classList.remove('show');
    this.alertExito.nativeElement.classList.add('d-none');
  }

  showAlertExito() {
    this.alertExito.nativeElement.classList.remove('d-none');
    this.alertExito.nativeElement.classList.add('show');
  }
  closeAlertOrden() {
    this.alertOrden.nativeElement.classList.remove('show');
    this.alertOrden.nativeElement.classList.add('d-none');
  }

  showAlertOrden() {
    this.alertOrden.nativeElement.classList.remove('d-none');
    this.alertOrden.nativeElement.classList.add('show');
  }

  closeAlertExitoOrden() {
    this.alertExitoOrden.nativeElement.classList.remove('show');
    this.alertExitoOrden.nativeElement.classList.add('d-none');
  }

  showAlertExitoOrden() {
    this.alertExitoOrden.nativeElement.classList.remove('d-none');
    this.alertExitoOrden.nativeElement.classList.add('show');
  }

  guardarEdicion() {
    this.adminSrv.modificarProd(this.adminSrv.token, this.producto).subscribe(
      (data) => {
        console.log(data);
        this.guardarSubsecciones(this.data);
        this.guardarImagenesNuevas(this.data);
        this.showAlertExito();
      },
      (error) => {
        console.log(error);
        this.showAlert();
      }
    );
  }

  subirProd() {
    this.ocultar = true;
    this.closeAlertExito();
    this.closeAlert();
    this.normalizarCampos();
    if (this.data) {
      this.guardarEdicion();
    } else {
      this.guardarNuevo();
    }
  }

  guardarNuevo() {
    if (isNullOrUndefined(this.producto.outlet)) {
      this.producto.outlet = false;
    }
    this.adminSrv.newProduct(this.adminSrv.token, this.producto).subscribe(
      (data) => {
        console.log(data);
        this.guardarSubsecciones(data.data.id);
        this.guardarImagenesNuevas(data.data.id);
        this.showAlertExito();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  guardarSubsecciones(id: number) {
    this.adminSrv
      .asignarSubsecciones(this.adminSrv.token, id, {
        subsecciones: this.subseccionesExistentes,
      })
      .subscribe(
        (data) => {
          console.log(data);
        },
        (error) => {
          console.log(error);
        }
      );
  }
  guardarImagenesNuevas(id: number) {
    let archivos = this.getFiles();
    let largo = this.uploader.queue.length - 1;
    let indice = 0;
    console.log(largo);
    archivos.forEach((element) => {
      const formData = new FormData();
      formData.append('file', element);

      let nombre = this.producto.code.split(' ').join('_');

      let params = this.adminSrv.token + '/' + id + '/' + nombre;

      return this.httpClient
        .post<any>(this.api.url + '/admin/newPicture/' + params, formData)
        .subscribe(
          (res) => {
            console.log(res);
            this.getProduct();
            console.log(indice, largo);
            this.eliminarArchivo(element);
            if (indice == largo) {
              this.ocultar = false;
            }
            indice = indice + 1;
          },
          (err) => {
            console.log(err);
            return err;
          }
        );
    });
    if (largo == -1) {
      this.ocultar = false;
    }
  }

  eliminarFoto(item: productPhotosModel) {
    this.adminSrv.deletePhoto(this.adminSrv.token, item).subscribe((data) => {
      console.log(data);
      this.getProduct();
    });
  }

  cerrarSesion() {
    this.authSrv.logout();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin']);
  }

  normalizarCampos() {
    if (this.producto.dateArrival == '') {
      this.producto.dateArrival = null;
    }
  }

  calcularPrecioxMenor() {
    this.producto.priceMin = this.producto.price * 2;
  }

  modificarOrden() {
    this.closeAlertExitoOrden();
    this.closeAlertOrden();
    console.log('fotosCargadas', this.fotosCargadas);

    this.adminSrv
      .ordenarFotos(this.adminSrv.token, this.fotosCargadas)
      .subscribe(
        (data) => {
          console.log(data);
          this.getProduct();
          this.showAlertExitoOrden();
        },
        (error) => {
          console.log(error);
          this.showAlertOrden();
        }
      );
  }

  cambiarOutlet() {
    if (this.producto.outlet) {
      this.producto.outlet = false;
    } else {
      this.producto.outlet = true;
      if (isNullOrUndefined(this.producto.minOutlet)) {
        this.producto.minOutlet = 1;
      }
    }
  }
}
