import {
  campaignModel,
  campaignUserModel,
} from './../../../models/campaign.model';
import { listaUsuarios, userModel } from './../../../models/user.model';
import { FileUploader } from 'ng2-file-upload';
import {
  Component,
  OnInit,
  HostListener,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import {
  MdbTablePaginationComponent,
  MdbTableDirective,
} from 'angular-bootstrap-md';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AdminModeloMailComponent } from '../admin-modelo-mail/admin-modelo-mail.component';
import { campaignNewModel } from 'src/app/models/campaign.model';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-admin-mail-campaign-maker',
  templateUrl: './admin-mail-campaign-maker.component.html',
  styleUrls: ['./admin-mail-campaign-maker.component.scss'],
})
export class AdminMailCampaignMakerComponent implements OnInit {
  usuarios: userModel[] = [];
  listaUsuarios: listaUsuarios[] = [];

  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true })
  mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('alert', { static: true })
  alert: ElementRef;
  @ViewChild('alertExito', { static: true }) alertExito: ElementRef;

  headElements = ['nombre', 'dni', 'email', 'telefono', 'alta'];

  searchText: string = '';
  previous: string;

  seleccionarTodo = false;
  editor = '';
  nombre = '';
  fecha = new Date().toISOString().slice(0, 10);
  tiempo = '00:00';

  idModif = 0;
  campaignModif: campaignModel = {} as campaignModel;
  campaignUserModif: campaignUserModel[] = [];

  public uploader: FileUploader = new FileUploader({
    queueLimit: 1,
  });
  public hasBaseDropZoneOver: boolean = false;

  constructor(
    private adminSrv: AdminService,
    private authSrv: AuthService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    public api: ApiService,
    private router: Router,
    private httpClient: HttpClient,
    private route: ActivatedRoute
  ) {}

  @HostListener('input') oninput() {
    this.searchItems();
  }

  ngOnInit() {
    this.adminSrv.reauth(localStorage.getItem('tokenAdmin')).subscribe(
      (data) => {
        this.adminSrv.setAuth();
        this.adminSrv.setToken(localStorage.getItem('tokenAdmin'));
        if (this.route.snapshot.paramMap.get('id')) {
          this.idModif = Number(this.route.snapshot.paramMap.get('id'));
        }
        this.getListado();
      },
      (error) => {
        this.router.onSameUrlNavigation = 'reload';
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate(['/admin']);
      }
    );
  }

  getFiles(): any {
    //recupero los items cargados en el input file
    return this.uploader.queue.map((fileItem) => {
      return fileItem._file;
    });
  }

  fileOverBase(ev): void {
    this.hasBaseDropZoneOver = ev;
  }

  eliminarArchivo(item: any) {
    this.uploader.queue.splice(this.uploader.queue.indexOf(item), 1);
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.listaUsuarios = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.listaUsuarios = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }

  getListado() {
    this.adminSrv.listadoUsuarios(this.adminSrv.token).subscribe(
      (data) => {
        this.usuarios = data.data;
        this.llenarLista();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  llenarLista() {
    this.listaUsuarios = [];
    for (let index = 0; index < this.usuarios.length; index++) {
      const element = this.usuarios[index];
      let item: listaUsuarios = {} as listaUsuarios;

      console.log('entro');

      item.alta = element.created_at;
      item.dni = element.dni;
      item.email = element.email;
      item.id = element.id;
      item.nombre = element.surname + ', ' + element.name;
      item.telefono = element.phone;
      item.selected = false;

      this.listaUsuarios.push(item);
    }
    this.mdbTable.setDataSource(this.listaUsuarios);
    this.listaUsuarios = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(8);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();

    if (this.idModif != 0) {
      this.getDetalles();
    }
  }

  seleccionarClientes(ev) {
    if (this.seleccionarTodo) {
      for (let index = 0; index < this.listaUsuarios.length; index++) {
        this.listaUsuarios[index].selected = true;
      }
    } else {
      for (let index = 0; index < this.listaUsuarios.length; index++) {
        this.listaUsuarios[index].selected = false;
      }
    }
  }

  verModeloMail() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '80vw';
    dialogConfig.panelClass = 'loginModal';
    if (this.uploader.queue.length > 0) {
      dialogConfig.data = {
        email: this.editor,
        adjunto: this.uploader.queue[0]._file,
      };
    } else {
      if (this.idModif != 0) {
        if (this.campaignModif.image) {
          dialogConfig.data = {
            email: this.editor,
            existente: this.api.urlImg + this.campaignModif.image,
          };
        } else {
          dialogConfig.data = { email: this.editor };
        }
      } else {
        dialogConfig.data = { email: this.editor };
      }
    }

    dialogConfig.closeOnNavigation = true;
    const dialogRef = this.dialog.open(AdminModeloMailComponent, dialogConfig);
  }

  guardarCampaign() {
    this.closeAlert();
    this.closeAlertExito();
    let enviar: campaignNewModel = {} as campaignNewModel;
    enviar.campaign = {} as campaignModel;
    enviar.users = [];

    enviar.campaign.name = this.nombre;
    enviar.campaign.sendDate = this.fecha + ' ' + this.tiempo + ':00';
    enviar.campaign.message = this.editor;
    enviar.campaign.image = null;

    this.listaUsuarios.forEach((element) => {
      if (element.selected) {
        enviar.users.push({ idUser: element.id, idCampaing: null });
      }
    });

    if (this.idModif != 0) {
      enviar.campaign.id = this.idModif;
      enviar.campaign.image = this.campaignModif.image;

      this.adminSrv.modifyCampaign(this.adminSrv.token, enviar).subscribe(
        (data) => {
          if (this.uploader.queue.length > 0) {
            this.guardarImagenesNuevas(this.idModif);
          }
          this.showAlertExito();
        },
        (error) => {
          this.showAlert();
        }
      );
    } else {
      this.adminSrv.newCampaign(this.adminSrv.token, enviar).subscribe(
        (data) => {
          if (this.uploader.queue.length > 0) {
            this.guardarImagenesNuevas(data.data.id);
          }
          this.showAlertExito();
        },
        (error) => {
          this.showAlert();
        }
      );
    }
  }

  guardarImagenesNuevas(id: number) {
    let archivos = this.getFiles();
    archivos.forEach((element) => {
      const formData = new FormData();
      formData.append('file', element);

      let nombre = this.nombre.split(' ').join('_').substr(0, 10);

      let params = id + '/' + nombre + '/' + this.adminSrv.token;

      return this.httpClient
        .post<any>(
          this.api.url + '/admin/campaigns/uploadFoto/' + params,
          formData
        )
        .subscribe(
          (res) => {
            console.log(res);
          },
          (err) => {
            console.log(err);
            return err;
          }
        );
    });
  }

  eliminarFoto() {
    this.adminSrv
      .deletePhotoCampaign(this.idModif, this.adminSrv.token)
      .subscribe((data) => {
        console.log(data);
        this.campaignModif.image = null;
      });
  }

  getDetalles() {
    this.adminSrv
      .getDetCampaign(this.adminSrv.token, this.idModif)
      .subscribe((data) => {
        this.campaignModif = data.data;
        this.campaignUserModif = data.users;
        this.nombre = this.campaignModif.name;
        this.fecha = this.campaignModif.sendDate.slice(0, 10);
        this.tiempo = this.campaignModif.sendDate.slice(11, 16);
        this.editor = this.campaignModif.message;

        console.log(this.campaignModif);

        this.campaignUserModif.forEach((element) => {
          let existe = this.listaUsuarios.filter((item) => {
            return item.id == element.idUser;
          });

          if (existe[0]) {
            this.listaUsuarios[
              this.listaUsuarios.indexOf(existe[0])
            ].selected = true;
          }
        });
      });
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

  showAlert() {
    this.alert.nativeElement.classList.remove('d-none');
    this.alert.nativeElement.classList.add('show');
  }

  closeAlertExito() {
    this.alertExito.nativeElement.classList.remove('show');
    this.alertExito.nativeElement.classList.add('d-none');
  }

  showAlertExito() {
    this.alertExito.nativeElement.classList.remove('d-none');
    this.alertExito.nativeElement.classList.add('show');
  }
}
