import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { OrdersService } from '../services/orders.service';

@Component({
  selector: 'app-boton-arrepentimiento',
  templateUrl: './boton-arrepentimiento.component.html',
  styleUrls: ['./boton-arrepentimiento.component.scss'],
})
export class BotonArrepentimientoComponent implements OnInit {
  code: string;
  @ViewChild('alert', { static: true }) alert: ElementRef;
  @ViewChild('alertExito', { static: true }) alertExito: ElementRef;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private orderSrv: OrdersService
  ) {}

  ngOnInit() {}

  cancelOrder() {
    this.orderSrv.cancelOrder({ code: this.code }).subscribe(
      (data) => {
        if (data.status == 'success') {
          this.showAlertExito();
        } else {
          this.showAlert();
        }
      },
      (error) => {
        console.log(error);
        this.showAlert();
      }
    );
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

  closeAlertExito() {
    this.alertExito.nativeElement.classList.remove('show');
    this.alertExito.nativeElement.classList.add('d-none');
  }

  irContacto() {
    this.router.navigate(['/contacto']);
    this.dialog.closeAll();
  }

  showAlert() {
    this.alert.nativeElement.classList.remove('d-none');
    this.alert.nativeElement.classList.add('show');
  }

  showAlertExito() {
    this.alertExito.nativeElement.classList.remove('d-none');
    this.alertExito.nativeElement.classList.add('show');
  }
}
