import { HttpClient } from "@angular/common/http";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from "angular-bootstrap-md";
import { listaDescuentosModel } from "src/app/models/order.model";
import { userModel } from "src/app/models/user.model";
import { AdminService } from "src/app/services/admin.service";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-informations",
  templateUrl: "./informations.component.html",
  styleUrls: ["./informations.component.scss"],
})
export class InformationsComponent implements OnInit {
  informations;
  @ViewChild("alert", { static: true }) alert: ElementRef;
  @ViewChild("alertExito", { static: true }) alertExito: ElementRef;

  constructor(
    private adminSrv: AdminService,
    public api: ApiService,
    private cdRef: ChangeDetectorRef,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    this.getInformations();
  }

  getInformations() {
    this.httpClient.get(this.api.url + "/admin/information").subscribe(
      (res) => {
        this.informations = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  updateInformations() {
    this.informations = this.informations;

    this.httpClient
      .put(this.api.url + "/admin/information", {
        informations: this.informations,
      })
      .subscribe(
        (res) => {
          this.showAlert("success");
        },
        (err) => {
          this.showAlert("error");
        }
      );
  }

  showAlert(type) {
    type === "success" ? this.showAlertExito() : this.showAlertError();
  }

  showAlertExito() {
    this.alertExito.nativeElement.classList.remove("d-none");
    this.alertExito.nativeElement.classList.add("show");
  }

  showAlertError() {
    this.alert.nativeElement.classList.remove("d-none");
    this.alert.nativeElement.classList.add("show");
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove("show");
    this.alert.nativeElement.classList.add("d-none");
  }

  closeAlertExito() {
    this.alertExito.nativeElement.classList.remove("show");
    this.alertExito.nativeElement.classList.add("d-none");
  }
}
