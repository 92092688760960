import { generalModel } from './../models/general.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  url: string = 'https://app.mohavejeans.com/api/v1';
  urlImg: string = 'https://gelljeans.s3-sa-east-1.amazonaws.com';
  general: generalModel = {} as generalModel;

  constructor(public http: HttpClient) {}

  private getHeaders(): HttpHeaders {
    const token = localStorage.getItem('tokenAdmin');

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (token) {
      headers = headers.set('Authorization', token);
    }

    return headers;
  }

  get(endpoint: string, params: string = '', options?: any): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(this.url + '/' + endpoint + params, { headers });
  }

  post(
    endpoint: string,
    body: any,
    params: string = '',
    options?: any
  ): Observable<any> {
    const headers = this.getHeaders();
    return this.http.post(this.url + '/' + endpoint + params, body, { headers });
  }

  put(
    endpoint: string,
    body: any,
    params: string = '',
    options?: any
  ): Observable<any> {
    const headers = this.getHeaders();
    return this.http.put(this.url + '/' + endpoint + params, body, { headers });
  }

  delete(
    endpoint: string,
    params: string = '',
    options?: any
  ): Observable<any> {
    const headers = this.getHeaders();
    return this.http.delete(this.url + '/' + endpoint + params, { headers });
  }

  patch(
    endpoint: string,
    body: any,
    params: string = '',
    options?: any
  ): Observable<any> {
    const headers = this.getHeaders();
    return this.http.put(this.url + '/' + endpoint + params, body, { headers });
  }
}
