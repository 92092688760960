import {
  listaOrdenItemsModel,
  listaOrdenItemsFinalModel,
  listaDescuentosModel,
} from './../../../models/order.model';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { ActivatedRoute } from '@angular/router';
import { listaOrdenModel } from 'src/app/models/order.model';
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from 'angular-bootstrap-md';

@Component({
  selector: 'app-admin-detalle-orden',
  templateUrl: './admin-detalle-orden.component.html',
  styleUrls: ['./admin-detalle-orden.component.scss'],
})
export class AdminDetalleOrdenComponent implements OnInit {
  listaOrdenes: listaOrdenModel = {} as listaOrdenModel;
  listaOrdenItems: listaOrdenItemsModel[] = [];
  listaOrdenItemsFinal: listaOrdenItemsFinalModel[] = [];
  discount: listaDescuentosModel = {} as listaDescuentosModel;

  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild('alert', { static: true }) alert: ElementRef;
  @ViewChild('alertExito', { static: true }) alertExito: ElementRef;

  headElements = ['codigo', 'talle', 'cantidad', 'precio'];

  previous: string;
  data: string;

  constructor(
    private adminSrv: AdminService,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.data = this.route.snapshot.paramMap.get('id');
    this.adminSrv.reauth(localStorage.getItem('tokenAdmin')).subscribe(
      (data) => {
        console.log('user', data);
        this.adminSrv.setAuth();
        this.adminSrv.setToken(localStorage.getItem('tokenAdmin'));
        this.adminSrv.setUserInfo(data.user);
        this.getListado();
      },
      (error) => {}
    );
  }

  getListado() {
    this.adminSrv.getOrden(this.adminSrv.token, Number(this.data)).subscribe(
      (data) => {
        this.listaOrdenes = data.data.orden;
        this.listaOrdenItems = data.data.items;
        if (!this.listaOrdenes.opened) {
          this.marcarLeida(this.listaOrdenes.id);
        }
        this.getDiscount(this.listaOrdenes.discountId);
        this.llenarLista();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  llenarLista() {
    this.listaOrdenItemsFinal = [];
    for (let index = 0; index < this.listaOrdenItems.length; index++) {
      const element = this.listaOrdenItems[index];
      let item: listaOrdenItemsFinalModel = {} as listaOrdenItemsFinalModel;

      console.log('entro');

      item.cantidad = element.quantity;
      item.precio = element.unitPrice;
      item.codigo = element.code;
      item.talle = element.size;

      this.listaOrdenItemsFinal.push(item);
    }

    this.mdbTable.setDataSource(this.listaOrdenItemsFinal);
    this.listaOrdenItemsFinal = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
    this.cdRef.detectChanges();
  }

  actualizarPedido() {
    this.closeAlert();
    this.closeAlertExito();
    this.adminSrv
      .updateOrder(this.adminSrv.token, Number(this.data), {
        observations: this.listaOrdenes.observations,
        status: this.listaOrdenes.status,
      })
      .subscribe(
        (data) => {
          this.showAlertExito();
        },
        (error) => {
          this.showAlert();
        }
      );
  }

  getDiscount(discountId: number) {
    this.adminSrv.getDiscountByOrder(this.adminSrv.token, discountId).subscribe(
      (data) => {
        if (data.data) {
          this.discount = data.data;
        }
      },
      (error) => {}
    );
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

  showAlert() {
    this.alert.nativeElement.classList.remove('d-none');
    this.alert.nativeElement.classList.add('show');
  }

  closeAlertExito() {
    this.alertExito.nativeElement.classList.remove('show');
    this.alertExito.nativeElement.classList.add('d-none');
  }

  showAlertExito() {
    this.alertExito.nativeElement.classList.remove('d-none');
    this.alertExito.nativeElement.classList.add('show');
  }

  marcarLeida(id: number) {
    this.adminSrv.marcarOrdenLeida(this.adminSrv.token, id).subscribe(
      (data) => {},
      (error) => {}
    );
  }
}
