import { generalModel } from './../models/general.model';
import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../services/products.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { BotonArrepentimientoComponent } from '../boton-arrepentimiento/boton-arrepentimiento.component';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss'],
})
export class PieComponent implements OnInit {
  generales: generalModel = {} as generalModel;
  constructor(private productSrv: ProductsService, private dialog: MatDialog) {}

  ngOnInit() {
    this.getGenerales();
  }

  getGenerales() {
    this.productSrv.getGenerales().subscribe(
      (data) => {
        this.generales = data.data;
      },
      (error) => {}
    );
  }

  irCancelarOrden() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '40vw';
    dialogConfig.panelClass = 'loginModal';
    dialogConfig.closeOnNavigation = true;
    const dialogRef = this.dialog.open(
      BotonArrepentimientoComponent,
      dialogConfig
    );
  }
}
