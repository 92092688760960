import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { userModel, listaUsuarios } from 'src/app/models/user.model';
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from 'angular-bootstrap-md';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AdminUsuariosDetalleComponent } from '../admin-usuarios-detalle/admin-usuarios-detalle.component';

@Component({
  selector: 'app-admin-usuarios',
  templateUrl: './admin-usuarios.component.html',
  styleUrls: ['./admin-usuarios.component.scss'],
})
export class AdminUsuariosComponent implements OnInit {
  usuarios: userModel[] = [];
  listaUsuarios: listaUsuarios[] = [];

  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true })
  mdbTablePagination: MdbTablePaginationComponent;

  headElements = ['id', 'nombre', 'dni', 'email', 'telefono', 'alta'];

  searchText: string = '';
  previous: string;

  constructor(
    private adminSrv: AdminService,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef
  ) {}

  @HostListener('input') oninput() {
    this.searchItems();
  }

  ngOnInit() {
    this.getListado();
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.listaUsuarios = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.listaUsuarios = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }

  getListado() {
    this.adminSrv.listadoUsuarios(this.adminSrv.token).subscribe(
      (data) => {
        this.usuarios = data.data;
        this.llenarLista();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  llenarLista() {
    this.listaUsuarios = [];
    for (let index = 0; index < this.usuarios.length; index++) {
      const element = this.usuarios[index];
      let item: listaUsuarios = {} as listaUsuarios;

      console.log('entro');

      item.alta = element.created_at;
      item.dni = element.dni;
      item.email = element.email;
      item.id = element.id;
      item.nombre = element.surname + ', ' + element.name;
      item.telefono = element.phone;

      this.listaUsuarios.push(item);
    }
    this.mdbTable.setDataSource(this.listaUsuarios);
    this.listaUsuarios = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(8);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
    console.log(this.listaUsuarios);
  }

  irDetalle(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '60vw';
    dialogConfig.panelClass = 'loginModal';
    dialogConfig.data = { id: id };
    dialogConfig.closeOnNavigation = true;
    const dialogRef = this.dialog.open(
      AdminUsuariosDetalleComponent,
      dialogConfig
    );
  }
}
