import { MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { userModel } from 'src/app/models/user.model';

@Component({
  selector: 'app-admin-usuarios-detalle',
  templateUrl: './admin-usuarios-detalle.component.html',
  styleUrls: ['./admin-usuarios-detalle.component.scss'],
})
export class AdminUsuariosDetalleComponent implements OnInit {
  usuario: userModel = {} as userModel;
  id: number;

  constructor(
    private adminSrv: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.id = this.data.id;
    this.getUsuario(this.id);
  }

  getUsuario(id: number) {
    this.adminSrv.getUsuario(this.adminSrv.token, id).subscribe(
      (data) => {
        this.usuario = data.data;
      },
      (error) => {}
    );
  }
}
