import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss'],
})
export class AdminMenuComponent implements OnInit {
  constructor(
    private authSrv: AuthService,
    private adminSrv: AdminService,
    private router: Router
  ) {}

  ngOnInit() {
    if (localStorage.getItem('tokenAdmin')) {
      this.adminSrv.reauth(localStorage.getItem('tokenAdmin')).subscribe(
        (data) => {
          this.adminSrv.setAuth();
          this.adminSrv.setToken(localStorage.getItem('tokenAdmin'));
        },
        (error) => {
          this.router.navigate(['/admin']);
        }
      );
    } else {
      this.router.navigate(['/admin']);
    }
  }

  cerrarSesion() {
    this.authSrv.logoutAdmin();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin']);
  }

  ir(ruta: string) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([ruta]);
  }
}
