import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild
} from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { AdminService } from 'src/app/services/admin.service';
import { AdminNuevoProductoComponent } from '../admin-nuevo-producto/admin-nuevo-producto.component';
import {
  productModel,
  productDetailsModel
} from 'src/app/models/products.model';

@Component({
  selector: 'app-admin-nuevo-producto-detail',
  templateUrl: './admin-nuevo-producto-detail.component.html',
  styleUrls: ['./admin-nuevo-producto-detail.component.scss']
})
export class AdminNuevoProductoDetailComponent implements OnInit {
  producto: productModel;
  productoDetalle: productDetailsModel = {} as productDetailsModel;
  @ViewChild('alert', { static: true }) alert: ElementRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productSrv: ProductsService,
    private adminSrv: AdminService,
    public dialogRef: MatDialogRef<AdminNuevoProductoComponent>
  ) {
    this.producto = data.producto;
  }

  ngOnInit() {}

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

  showAlert() {
    this.alert.nativeElement.classList.remove('d-none');
    this.alert.nativeElement.classList.add('show');
  }

  guardarEdicion() {
    this.adminSrv
      .newProductDetail(
        this.adminSrv.token,
        this.producto.id,
        this.productoDetalle
      )
      .subscribe(
        data => {
          console.log(data);
          this.dialogRef.close({ conDatos: true });
        },
        error => {
          console.log(error);
          this.showAlert();
        }
      );
  }
}
