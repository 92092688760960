import { userDataResponse, userModel } from './../models/user.model';
import { UserService } from './../services/user.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DomiciliosComponent } from '../domicilios/domicilios.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  logueado = false;

  userData: userDataResponse = {} as userDataResponse;

  @ViewChild('alert', { static: true }) alert: ElementRef;

  constructor(
    private authSrv: AuthService,
    private userSrv: UserService,
    private router: Router
  ) {
    this.userData.data = {} as userModel;
  }

  ngOnInit() {
    this.authSrv.reauth(localStorage.getItem('token')).subscribe(
      data => {
        this.authSrv.setAuth();
        this.authSrv.setToken(localStorage.getItem('token'));
        this.authSrv.setUserInfo(data.user);
        this.logueado = this.authSrv.isAuth();
        this.getUserData();
      },
      error => {
        this.router.navigate(['/register']);
      }
    );
  }

  getUserData() {
    this.userSrv.getUserData(this.authSrv.userInfo.id).subscribe(
      data => {
        console.log(data);
        this.userData = data;
      },
      error => {}
    );
  }

  modificarDatos() {
    this.userSrv.updateProfile(this.userData.data).subscribe(
      data => {
        this.showAlert();
      },
      error => {
        console.log(error);
      }
    );
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

  showAlert() {
    this.alert.nativeElement.classList.add('show');
    this.alert.nativeElement.classList.remove('d-none');
  }
}
