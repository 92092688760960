import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CartService } from '../services/cart.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  @ViewChild('alert', { static: true }) alert: ElementRef;

  constructor(
    private authSrv: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private cartSrv: CartService
  ) {}

  ngOnInit() {}

  loginUser() {
    const loginObj = {
      email: this.email,
      password: this.password,
    };
    this.authSrv.login(loginObj).subscribe(
      (data) => {
        console.log(data);
        localStorage.setItem('token', data.api_key);
        this.authSrv.setToken(data.api_key);
        this.authSrv.setAuth();
        this.dialog.closeAll();
      },
      (error) => {
        console.log(error);
        this.showAlert();
      }
    );
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

  irRegistrarse() {
    this.router.navigate(['/register']);
    this.dialog.closeAll();
  }
  irOlvide() {
    this.router.navigate(['/olvide_contrasena']);
    this.dialog.closeAll();
  }

  showAlert() {
    this.alert.nativeElement.classList.remove('d-none');
    this.alert.nativeElement.classList.add('show');
  }
}
