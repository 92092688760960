import { HttpClient } from "@angular/common/http";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from "angular-bootstrap-md";
import { listaDescuentosModel } from "src/app/models/order.model";
import { userModel } from "src/app/models/user.model";
import { AdminService } from "src/app/services/admin.service";
import { ApiService } from "src/app/services/api.service";
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-locations",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.scss"],
})
export class LocationsComponent implements OnInit {
  @ViewChild('alertError', { static: true }) alertError: ElementRef;
  @ViewChild('alertExito', { static: true }) alertExito: ElementRef;
  latitudeCenter = -31.416668;
  longitudeCenter = -64.183334;
  locations: Array<any>;
  latitude: number;
  longitude: number;
  address: string;
  query: string = 'Av Velez Sarsfield 423';
  zoom = 14;
  suggestions: Array<any>;
  googleApiKey: string;

  constructor(
    private adminSrv: AdminService,
    public api: ApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private httpClient: HttpClient,
  ) {}

  ngOnInit() {
    this.getLocations();
    this.googleApiKey = environment.googleApiKey;
  }

  getLocations() {
    this.httpClient.get(this.api.url + "/admin/locations").subscribe(
      (res: any) => {
        this.locations = res.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onMapClick(event: any) {
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
  }

  searchSuggestions() {
    this.httpClient.get(this.api.url + `/admin/googlePlaces?query=${this.query}`).subscribe(
      (res: any) => {
        this.suggestions = res.predictions;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  chooseSuggestion(suggestion: any) {
    this.httpClient
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?place_id=${suggestion.place_id}&key=${this.googleApiKey}`
      )
      .subscribe(
        (response: any) => {
          if (response.status === "OK" && response.results.length > 0) {
            this.latitude = response.results[0].geometry.location.lat;
            this.longitude = response.results[0].geometry.location.lng;
            this.address = response.results[0].formatted_address;

            this.saveLocation();

            this.changeDetectorRef.detectChanges();
            this.suggestions = [];
          } else {
            console.error("Error al obtener la ubicación seleccionada");
          }
        },
        (error) => {
          console.error(
            "Error en la llamada a la API de geocodificación",
            error
          );
        }
      );
  }

  saveLocation() {
    this.httpClient
      .post(this.api.url + "/admin/locations", {
        latitude: this.latitude,
        longitude: this.longitude,
        address: this.address,
      })
      .subscribe(
        (res) => {
          this.getLocations();
          this.showAlertExito();
          this.query = '';
        },
        (err) => {
          this.showAlertError();
        }
      );
  }

  removeLocation(location) {
    this.httpClient
      .delete(this.api.url + "/admin/locations/" + location.id)
      .subscribe(
        (res) => {
          this.showAlertExito();
          this.getLocations();
        },
        (err) => {
          this.showAlertError();
        }
      );
  }

  closeAlertError() {
    this.alertError.nativeElement.classList.remove("show");
    this.alertError.nativeElement.classList.add("d-none");
  }

  showAlertError() {
    this.alertError.nativeElement.classList.remove("d-none");
    this.alertError.nativeElement.classList.add("show");
  }

  closeAlertExito() {
    this.alertExito.nativeElement.classList.remove("show");
    this.alertExito.nativeElement.classList.add("d-none");
  }

  showAlertExito() {
    this.alertExito.nativeElement.classList.remove("d-none");
    this.alertExito.nativeElement.classList.add("show");
  }
}
