import { AuthService } from './../services/auth.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../services/user.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { CartService } from '../services/cart.service';
import { RegistroExitoComponent } from '../registro-exito/registro-exito.component';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss'],
})
export class RegistroComponent implements OnInit {
  nombre: any;
  apellido: any;
  password: any;
  email: any;

  error = ' Ocurrio un problema. Intente de nuevo más tarde';

  @ViewChild('alert', { static: true }) alert: ElementRef;
  @ViewChild('alertError', { static: true }) alertError: ElementRef;

  logueado = false;
  constructor(
    private userSrv: UserService,
    private dialog: MatDialog,
    private authSrv: AuthService,
    private router: Router,
    private cartSrv: CartService
  ) {}

  ngOnInit() {}

  registrar() {
    const newUser = {
      email: this.email,
      password: this.password,
      name: this.nombre,
      surname: this.apellido,
    };
    this.userSrv.registerUser(newUser).subscribe(
      (data) => {
        localStorage.setItem('token', data.data.api_token);
        this.authSrv.setToken(data.data.api_token);
        this.authSrv.setAuth();
        this.irExito();
      },
      (error) => {
        console.log(error.error.message);
        if (error.error.message) {
          this.error =
            error.error.message +
            "<a href='/olvide_contrasena'> Hace click aqui para recuperar tu contraseña</a>";
        }

        this.showAlertError();
      }
    );
  }

  irLogin() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '40vw';
    dialogConfig.panelClass = 'loginModal';
    dialogConfig.closeOnNavigation = true;
    const dialogRef = this.dialog.open(LoginComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.authSrv.reauth(localStorage.getItem('token')).subscribe((data) => {
        this.authSrv.setAuth();
        this.authSrv.setToken(localStorage.getItem('token'));
        this.logueado = this.authSrv.isAuth();
        this.authSrv.setUserInfo(data.user);
        this.cartSrv.updateCartNumber(this.authSrv.userInfo.id);
        this.router.onSameUrlNavigation = 'reload';
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/']);
      });
    });
  }

  irExito() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '30vw';
    // dialogConfig.panelClass = 'loginModal';
    dialogConfig.data = { mensaje: 'Usuario registrado exitosamente.' };
    dialogConfig.closeOnNavigation = true;
    const dialogRef = this.dialog.open(RegistroExitoComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.router.onSameUrlNavigation = 'reload';
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/']);
    });
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

  showAlert() {
    this.alert.nativeElement.classList.remove('d-none');
    this.alert.nativeElement.classList.add('show');
  }

  closeAlertError() {
    this.alertError.nativeElement.classList.remove('show');
    this.alertError.nativeElement.classList.add('d-none');
  }

  showAlertError() {
    this.alertError.nativeElement.classList.remove('d-none');
    this.alertError.nativeElement.classList.add('show');
  }
}
