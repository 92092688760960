import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from './api.service';
import { cartResponseModel } from '../models/cart.model';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(private api: ApiService) {}

  cartNumber: number = 0;

  private itemAdd = new Subject<string>();
  private itemDelete = new Subject<string>();

  itemAdd$ = this.itemAdd.asObservable();
  itemDelete$ = this.itemDelete.asObservable();

  private getResponse(endpoint: string, params?: string) {
    return this.api.get(endpoint, params);
  }

  private postResponse(endpoint: string, contenido: any, params?: string) {
    return this.api.post(endpoint, contenido, params);
  }

  addItem(items: string) {
    this.itemAdd.next(items);
  }

  deleteItem(items: string) {
    this.itemDelete.next(items);
  }

  getCart(idUser: number, outlet: number) {
    return this.getResponse('cart/getCart/' + idUser + '/' + outlet);
  }

  validarCant(idUser: number, outlet: number) {
    return this.getResponse('cart/validarCant/' + idUser + '/' + outlet);
  }

  editUserCart(idUser: number, outlet: number) {
    let objeto = {
      idUser: idUser,
      totalPrice: 0,
    };
    return this.postResponse('cart/editCart/' + idUser + '/' + outlet, objeto);
  }

  userCartAddItem(idUser: number, contenido: any, outlet: number) {
    return this.postResponse(
      'cart/addItem/' + idUser + '/' + outlet,
      contenido
    );
  }

  userCartRemoveItem(idCart: number, outlet: number, contenido: any) {
    return this.postResponse(
      'cart/deleteItem/' + idCart + '/' + outlet,
      contenido
    );
  }

  userCartEditItem(idCartItem: number, contenido: any, outlet: number) {
    return this.postResponse(
      'cart/editCartItems/' + idCartItem + '/' + outlet,
      contenido
    );
  }

  updateCartNumber(idUser: number) {
    let total = 0;
    let carrito: cartResponseModel;
    this.getCart(idUser, 0).subscribe((data) => {
      carrito = data;

      for (let index = 0; index < carrito.cartDetails.length; index++) {
        const element = carrito.cartDetails[index];
        total += element.cartDetail.quantity;
      }

      this.cartNumber = total;
    });
  }

  eliminarCart($idUser) {
    return this.postResponse('cart/deleteFullCart/' + $idUser, null);
  }
}
