import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'gellijeans-frontend';

  router: string = '';
  constructor(private _router: Router) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log('this is what your looking for ', event.url);
        if (event.url) {
          this.router = event.url;
        }
      }
    });
  }

  ngOnInit() {}
}
