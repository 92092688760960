import { AdminNuevoEnvioComponent } from './admin/envios/admin-nuevo-envio/admin-nuevo-envio.component';
import { AdminEnviosComponent } from './admin/envios/admin-envios/admin-envios.component';
import { AdminProductsComponent } from './admin/productos/admin-products/admin-products.component';
import { AdminMailCampaignMakerComponent } from './admin/campaign/admin-mail-campaign-maker/admin-mail-campaign-maker.component';
import { AdminListaOrdenesComponent } from './admin/ordenes/admin-lista-ordenes/admin-lista-ordenes.component';
import { AdminUsuariosDetalleComponent } from './admin/usuarios/admin-usuarios-detalle/admin-usuarios-detalle.component';
import { AdminUsuariosComponent } from './admin/usuarios/admin-usuarios/admin-usuarios.component';
import { AdminProductsDetailsComponent } from './admin/productos/admin-products-details/admin-products-details.component';
import { CarritoComponent } from './carrito/carrito.component';
import { ProductosComponent } from './productos/productos.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactoComponent } from './contacto/contacto.component';
import { RegistroComponent } from './registro/registro.component';
import { PerfilComponent } from './perfil/perfil.component';
import { OrdenComponent } from './orden/orden.component';
import { ProductoDetalleComponent } from './producto-detalle/producto-detalle.component';
import { QuienesSomosComponent } from './quienes-somos/quienes-somos.component';
import { TrabajaNosotrosComponent } from './trabaja-nosotros/trabaja-nosotros.component';
import { LoginAdminComponent } from './admin/login-admin/login-admin.component';
import { AdminNuevoProductoComponent } from './admin/productos/admin-nuevo-producto/admin-nuevo-producto.component';
import { PasswordResetComponent } from './password/password-reset/password-reset.component';
import { PasswordNewComponent } from './password/password-new/password-new.component';
import { AdminDetalleOrdenComponent } from './admin/ordenes/admin-detalle-orden/admin-detalle-orden.component';
import { AdminMailCampaignDashboardComponent } from './admin/campaign/admin-mail-campaign-dashboard/admin-mail-campaign-dashboard.component';
import { AdminGeneralComponent } from './admin/admin-general/admin-general.component';
import { CerradoVacacionesComponent } from './cerrado-vacaciones/cerrado-vacaciones.component';
import { DiscountsListComponent } from './admin/discounts/discounts-list/discounts-list.component';
import { DiscountsNewComponent } from './admin/discounts/discounts-new/discounts-new.component';
import { InformationsComponent } from './admin/informations/informations.component';
import { LocationsComponent } from './admin/locations/locations.component';

const routes: Routes = [
  {
    path: '',
    component: LoginAdminComponent,
  },
  {
    path: 'register',
    component: RegistroComponent,
  },
  {
    path: 'contacto',
    component: ContactoComponent,
  },
  {
    path: 'catalogo/:tipo',
    component: ProductosComponent,
  },
  {
    path: 'perfil',
    component: PerfilComponent,
  },
  {
    path: 'cart',
    component: CarritoComponent,
  },
  {
    path: 'orden/:tipo',
    component: OrdenComponent,
  },
  {
    path: 'product_detalle',
    component: ProductoDetalleComponent,
  },
  {
    path: 'quienes_somos',
    component: QuienesSomosComponent,
  },
  {
    path: 'trabaja_nosotros',
    component: TrabajaNosotrosComponent,
  },
  {
    path: 'admin',
    component: LoginAdminComponent,
  },
  {
    path: 'admin/dashboard/:tipo',
    component: AdminProductsComponent,
  },
  {
    path: 'admin/nuevoProd',
    component: AdminNuevoProductoComponent,
  },
  {
    path: 'admin/productsDetail',
    component: AdminProductsDetailsComponent,
  },
  {
    path: 'olvide_contrasena',
    component: PasswordResetComponent,
  },
  {
    path: 'nueva_contrasena/:token',
    component: PasswordNewComponent,
  },
  {
    path: 'admin/usuarios',
    component: AdminUsuariosComponent,
  },
  {
    path: 'admin/usuariosDet/:id',
    component: AdminUsuariosDetalleComponent,
  },
  {
    path: 'admin/ordenes',
    component: AdminListaOrdenesComponent,
  },
  {
    path: 'admin/orden/:id',
    component: AdminDetalleOrdenComponent,
  },
  {
    path: 'admin/campaigns',
    component: AdminMailCampaignDashboardComponent,
  },
  {
    path: 'admin/campaignsmaker',
    component: AdminMailCampaignMakerComponent,
  },
  {
    path: 'admin/general',
    component: AdminGeneralComponent,
  },
  {
    path: 'admin/envios',
    component: AdminEnviosComponent,
  },
  {
    path: 'admin/nuevoEnvio',
    component: AdminNuevoEnvioComponent,
  },
  {
    path: 'cerrado',
    component: CerradoVacacionesComponent,
  },
  {
    path: 'admin/discounts',
    component: DiscountsListComponent,
  },
  {
    path: 'admin/nuevoDescuento',
    component: DiscountsNewComponent,
  },
  {
    path: 'admin/informations',
    component: InformationsComponent,
  },
  {
    path: 'admin/locations',
    component: LocationsComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
