import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CartService } from '../services/cart.service';
import { cartResponseModel } from '../models/cart.model';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import { DomiciliosComponent } from '../domicilios/domicilios.component';
import { OrdersService } from '../services/orders.service';
import { isNullOrUndefined } from 'util';
import { userDataResponse, userModel } from '../models/user.model';
import { UserService } from '../services/user.service';
import { ProductsService } from '../services/products.service';
import { generalModel } from '../models/general.model';

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss'],
})
export class CarritoComponent implements OnInit {
  logueado = false;
  generales: generalModel;
  carrito: cartResponseModel;
  tieneOrdenPrevia: boolean = false;
  montoMin: number;
  userData: userDataResponse = {} as userDataResponse;

  outlet: boolean = false;

  constructor(
    private authSrv: AuthService,
    private cartSrv: CartService,
    private orderSrv: OrdersService,
    public userSrv: UserService,
    public api: ApiService,
    public router: Router,
    private dialog: MatDialog,
    private productSrv: ProductsService
  ) {
    this.userData.data = {} as userModel;
  }

  ngOnInit() {
    this.getGenerales();
    this.authSrv.reauth(localStorage.getItem('token')).subscribe(
      (data) => {
        this.authSrv.setAuth();
        this.authSrv.setToken(localStorage.getItem('token'));
        this.authSrv.setUserInfo(data.user);
        this.getUserData();
        this.logueado = this.authSrv.isAuth();
        this.getCart();
        this.getOrder();
      },
      (error) => {}
    );
  }

  getUserData() {
    this.userSrv.getUserData(this.authSrv.userInfo.id).subscribe(
      (data) => {
        console.log(data);
        this.userData = data;
      },
      (error) => {}
    );
  }

  getCart() {
    this.cartSrv
      .getCart(this.authSrv.userInfo.id, Number(this.outlet))
      .subscribe(
        (data: cartResponseModel) => {
          console.log('sin ordenar', data);
          data.cartDetails.sort((a, b) => {
            return a.cartDetail.id - b.cartDetail.id;
          });
          console.log('sin ordenar', data);
          this.cartSrv.updateCartNumber(this.authSrv.userInfo.id);
          this.carrito = data;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getOrder() {
    this.orderSrv.getOrders(this.authSrv.userInfo.id).subscribe(
      (data) => {
        if (!isNullOrUndefined(data.order)) {
          this.tieneOrdenPrevia = true;
          this.montoMin = Number(this.generales.topeMinimo);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  subtotal(precio: number, cant: number): number {
    return precio * cant;
  }
  eliminarItem(idItem: number) {
    this.cartSrv
      .userCartRemoveItem(idItem, Number(this.outlet), null)
      .subscribe(
        (data) => {
          this.cartSrv.updateCartNumber(this.authSrv.userInfo.id);
          this.getCart();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  cambiarCantidad(
    cantidad: number,
    cartItem: number,
    productSubitemid: number
  ) {
    if (cantidad == 0) {
      this.eliminarItem(cartItem);
    } else {
      let item = {
        idProductDetail: productSubitemid,
        quantity: cantidad,
      };

      this.cartSrv
        .userCartEditItem(cartItem, item, Number(this.outlet))
        .subscribe(
          (data) => {
            console.log(data);
            this.cartSrv.updateCartNumber(this.authSrv.userInfo.id);
            this.getCart();
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  realizarPedido() {
    this.getCart();
    if (this.authSrv.userInfo.dni != null) {
      if (this.outlet) {
        this.router.navigate(['/orden/1']);
      } else {
        this.router.navigate(['/orden/0']);
      }
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.minWidth = '40vw';
      dialogConfig.panelClass = 'loginModal';
      dialogConfig.closeOnNavigation = true;
      const dialogRef = this.dialog.open(DomiciliosComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result) => {
        this.router.navigate(['/perfil']);
      });
    }
  }

  superaMonto(total: number): boolean {
    if (total < this.montoMin) {
      return false;
    } else {
      return true;
    }
  }

  getGenerales() {
    this.productSrv.getGenerales().subscribe(
      (data) => {
        this.generales = data.data;
        this.montoMin = Number(this.generales.topeMaximo);
        let desde = new Date(
          this.generales.inactivoDesde.slice(0, 10) + 'T00:00'
        );
        let hasta = new Date(
          this.generales.inactivoHasta.slice(0, 10) + 'T23:59'
        );
        let hoy = new Date();

        // if (
        //   hoy.getTime() >= desde.getTime() &&
        //   hoy.getTime() <= hasta.getTime()
        // ) {
        //   if (
        //     this.authSrv.userInfo.email != 'testing@gelljeans.com.ar' &&
        //     this.authSrv.userInfo.email != 'chinty_94@hotmail.com'
        //   ) {
        //     this.router.navigate(['/cerrado']);
        //   }
        // }
      },
      (error) => {}
    );
  }

  cambiarOutlet(ev: any) {
    this.outlet = ev.index == 0 ? false : true;
    this.getCart();
  }

  cantidadesOutlet(): boolean {
    let resultado = true;
    if (this.outlet) {
      this.carrito.cartDetails.forEach((item) => {
        if (item.product.minOutlet > item.cartDetail.quantity) {
          resultado = false;
        }
      });
    }

    return resultado;
  }
}
