import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-slider-promo',
  templateUrl: './slider-promo.component.html',
  styleUrls: ['./slider-promo.component.scss']
})
export class SliderPromoComponent implements OnInit {
  @Input() banner: string;

  constructor(public api: ApiService) {}

  ngOnInit() {}
}
