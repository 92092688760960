import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { RegistroExitoComponent } from 'src/app/registro-exito/registro-exito.component';

@Component({
  selector: 'app-password-new',
  templateUrl: './password-new.component.html',
  styleUrls: ['./password-new.component.scss']
})
export class PasswordNewComponent implements OnInit {
  password1 = '';
  password2 = '';
  token = '';
  @ViewChild('alert', { static: true }) alert: ElementRef;
  @ViewChild('alertError', { static: true }) alertError: ElementRef;
  @ViewChild('loader', { static: true }) loader: ElementRef;

  constructor(
    private userSrv: UserService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');
      console.log('token', this.token);
    });
  }

  cambio_password() {
    if (this.password1 === this.password2) {
      this.showLoader();
      const cambio = { password: this.password1, token: this.token + '==' };
      this.userSrv.changePassword(cambio).subscribe(
        data => {
          this.hideLoader();
          this.irExito();
        },
        error => {
          this.hideLoader();
        }
      );
    } else {
      this.showAlertError();
    }
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

  showAlert() {
    this.alert.nativeElement.classList.add('show');
    this.alert.nativeElement.classList.remove('d-none');
  }

  closeAlertError() {
    this.alertError.nativeElement.classList.remove('show');
    this.alertError.nativeElement.classList.add('d-none');
  }

  showAlertError() {
    this.alertError.nativeElement.classList.add('show');
    this.alertError.nativeElement.classList.remove('d-none');
  }

  showLoader() {
    this.loader.nativeElement.classList.remove('d-none');
  }

  hideLoader() {
    this.loader.nativeElement.classList.add('d-none');
  }

  irExito() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '30vw';
    // dialogConfig.panelClass = 'loginModal';
    dialogConfig.data = { mensaje: '¡Exito! Su contraseña fue cambiada.' };
    dialogConfig.closeOnNavigation = true;
    const dialogRef = this.dialog.open(RegistroExitoComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.router.onSameUrlNavigation = 'reload';
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/']);
    });
  }
}
