import { Component, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { productResponseModel } from '../models/products.model';
import { ApiService } from '../services/api.service';
import { ProductsService } from '../services/products.service';
import { AuthService } from '../services/auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ProductoDetalleComponent } from '../producto-detalle/producto-detalle.component';
import { Router } from '@angular/router';
import { generalModel } from '../models/general.model';

@Component({
  selector: 'app-destacados',
  templateUrl: './destacados.component.html',
  styleUrls: ['./destacados.component.scss'],
})
export class DestacadosComponent implements OnInit {
  productReponseGJ: productResponseModel[] = [];
  productReponseMJ: productResponseModel[] = [];
  productReponsePA: productResponseModel[] = [];
  generales: generalModel;

  public carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 2, md: 3, lg: 3, all: 0 },
    slide: 3,
    speed: 250,
    point: {
      visible: false,
    },
    load: 2,
    velocity: 0,
    loop: true,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)',
  };
  logueado = false;
  constructor(
    public api: ApiService,
    private productSrv: ProductsService,
    private dialog: MatDialog,
    private authSrv: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getGenerales();
    this.authSrv.reauth(localStorage.getItem('token')).subscribe((data) => {
      this.authSrv.setAuth();
      this.authSrv.setToken(localStorage.getItem('token'));
      this.logueado = this.authSrv.isAuth();
    });
    this.getProducts();
  }

  // RECUPERO SOLO LOS PRODUCTOS DE MOHAVE Y SILANDRE
  getProducts() {
    this.productSrv.getProductByMarca(1).subscribe(
      (data) => {
        console.log(data);
        //this.productReponse = data.data;
        this.ultimosTres(1, data.data);
      },
      (error) => {
        console.log(error);
      }
    );

    this.productSrv.getProductByMarca(2).subscribe(
      (data) => {
        console.log(data);
        //this.productReponse = data.data;
        this.ultimosTres(2, data.data);
      },
      (error) => {
        console.log(error);
      }
    );

    this.productSrv.getProductByMarca(3).subscribe(
      (data) => {
        console.log(data);
        //this.productReponse = data.data;
        this.ultimosTres(3, data.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getGenerales() {
    this.productSrv.getGenerales().subscribe(
      (data) => {
        this.generales = data.data;
      },
      (error) => {}
    );
  }

  irProducto(id: number) {
    this.router.navigate(['/product_detalle', { id: id }]);
    /* 
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { id: id };
    dialogConfig.minWidth = '50vw';
    this.dialog.open(ProductoDetalleComponent, dialogConfig); */
  }

  ultimosTres(tipo: number, productos: productResponseModel[]) {
    console.log(1, productos);
    let listaOrdenada: productResponseModel[] = productos.sort((a, b) =>
      a.product.id < b.product.id ? 1 : b.product.id < a.product.id ? -1 : 0
    );
    listaOrdenada = listaOrdenada.filter((item) => {
      return item.product.active == true;
    });
    const max = listaOrdenada.length < 3 ? listaOrdenada.length : 3;
    switch (tipo) {
      case 2:
        for (let index = 0; index < max; index++) {
          if (listaOrdenada[index].product) {
            this.productReponseMJ.push(listaOrdenada[index]);
          }
        }
        break;

      case 3:
        for (let index = 0; index < max; index++) {
          if (listaOrdenada[index].product) {
            this.productReponsePA.push(listaOrdenada[index]);
          }
        }
        break;

      default:
        for (let index = 0; index < max; index++) {
          if (listaOrdenada[index].product) {
            this.productReponseGJ.push(listaOrdenada[index]);
          }
        }
        break;
    }
  }
}
