import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BarraComponent } from './barra/barra.component';
import { SliderPromoComponent } from './slider-promo/slider-promo.component';
import { DestacadosComponent } from './destacados/destacados.component';
import { PieComponent } from './pie/pie.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NguCarouselModule } from '@ngu/carousel';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ContactoComponent } from './contacto/contacto.component';
import { ProductosComponent } from './productos/productos.component';
import { ProductoDetalleComponent } from './producto-detalle/producto-detalle.component';
import { CarritoComponent } from './carrito/carrito.component';
import { PerfilComponent } from './perfil/perfil.component';
import { DomiciliosComponent } from './domicilios/domicilios.component';
import { RegistroComponent } from './registro/registro.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule, MatTabsModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrdenComponent } from './orden/orden.component';
import { QuienesSomosComponent } from './quienes-somos/quienes-somos.component';
import { RegistroExitoComponent } from './registro-exito/registro-exito.component';
import { TrabajaNosotrosComponent } from './trabaja-nosotros/trabaja-nosotros.component';
import { LoginAdminComponent } from './admin/login-admin/login-admin.component';
import { AdminProductsComponent } from './admin/productos/admin-products/admin-products.component';
import { AdminProductsDetailsComponent } from './admin/productos/admin-products-details/admin-products-details.component';
import { AdminNuevoProductoComponent } from './admin/productos/admin-nuevo-producto/admin-nuevo-producto.component';
import { AdminNuevoProductoDetailComponent } from './admin/productos/admin-nuevo-producto-detail/admin-nuevo-producto-detail.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FileUploadModule } from 'ng2-file-upload';
import { ImagePreview } from './image-preview.directive';
import { PasswordResetComponent } from './password/password-reset/password-reset.component';
import { PasswordNewComponent } from './password/password-new/password-new.component';
import { AdminUsuariosComponent } from './admin/usuarios/admin-usuarios/admin-usuarios.component';
import { AdminUsuariosDetalleComponent } from './admin/usuarios/admin-usuarios-detalle/admin-usuarios-detalle.component';
import { AdminMenuComponent } from './admin/admin-menu/admin-menu.component';
import { AdminListaOrdenesComponent } from './admin/ordenes/admin-lista-ordenes/admin-lista-ordenes.component';
import { AdminDetalleOrdenComponent } from './admin/ordenes/admin-detalle-orden/admin-detalle-orden.component';
import { AdminMailCampaignDashboardComponent } from './admin/campaign/admin-mail-campaign-dashboard/admin-mail-campaign-dashboard.component';
import { AdminMailCampaignMakerComponent } from './admin/campaign/admin-mail-campaign-maker/admin-mail-campaign-maker.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { AdminModeloMailComponent } from './admin/campaign/admin-modelo-mail/admin-modelo-mail.component';
import { AdminGeneralComponent } from './admin/admin-general/admin-general.component';
import { CerradoVacacionesComponent } from './cerrado-vacaciones/cerrado-vacaciones.component';
import { AdminEnviosComponent } from './admin/envios/admin-envios/admin-envios.component';
import { AdminNuevoEnvioComponent } from './admin/envios/admin-nuevo-envio/admin-nuevo-envio.component';
import { DiscountsListComponent } from './admin/discounts/discounts-list/discounts-list.component';
import { DiscountsNewComponent } from './admin/discounts/discounts-new/discounts-new.component';
import { BotonArrepentimientoComponent } from './boton-arrepentimiento/boton-arrepentimiento.component';
import { CarouselImagesLoadedComponent } from './admin/admin-general/carousel-images-loaded/carousel-images-loaded';
import { CarouselDescriptionComponent } from './admin/admin-general/carousel-description/carousel-description';
import { InformationsComponent } from './admin/informations/informations.component';
import { LocationsComponent } from './admin/locations/locations.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    BarraComponent,
    SliderPromoComponent,
    DestacadosComponent,
    PieComponent,
    LoginComponent,
    HomeComponent,
    ContactoComponent,
    ProductosComponent,
    ProductoDetalleComponent,
    CarritoComponent,
    PerfilComponent,
    DomiciliosComponent,
    RegistroComponent,
    OrdenComponent,
    QuienesSomosComponent,
    RegistroExitoComponent,
    TrabajaNosotrosComponent,
    LoginAdminComponent,
    AdminProductsComponent,
    AdminProductsDetailsComponent,
    AdminNuevoProductoComponent,
    AdminNuevoProductoDetailComponent,
    ImagePreview,
    PasswordResetComponent,
    PasswordNewComponent,
    AdminUsuariosComponent,
    AdminUsuariosDetalleComponent,
    AdminMenuComponent,
    AdminListaOrdenesComponent,
    AdminDetalleOrdenComponent,
    AdminMailCampaignDashboardComponent,
    AdminMailCampaignMakerComponent,
    AdminModeloMailComponent,
    AdminGeneralComponent,
    CerradoVacacionesComponent,
    AdminEnviosComponent,
    AdminNuevoEnvioComponent,
    DiscountsListComponent,
    DiscountsNewComponent,
    BotonArrepentimientoComponent,
    CarouselImagesLoadedComponent,
    CarouselDescriptionComponent,
    InformationsComponent,
    LocationsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    NguCarouselModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSidenavModule,
    FormsModule,
    FileUploadModule,
    ReactiveFormsModule,
    CKEditorModule,
    MatTabsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    LoginComponent,
    DomiciliosComponent,
    RegistroExitoComponent,
    AdminNuevoProductoDetailComponent,
    AdminUsuariosDetalleComponent,
    AdminModeloMailComponent,
    BotonArrepentimientoComponent,
  ],
})
export class AppModule {}
