import { MatDialog } from '@angular/material';
import { userModel } from './../../../models/user.model';
import { listaDescuentosModel } from 'src/app/models/order.model';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as uuid from 'uuid';
import { AdminService } from 'src/app/services/admin.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-discounts-new',
  templateUrl: './discounts-new.component.html',
  styleUrls: ['./discounts-new.component.scss'],
})
export class DiscountsNewComponent implements OnInit {
  discount: listaDescuentosModel = {} as listaDescuentosModel;
  user: userModel = {} as userModel;
  @ViewChild('alert', { static: true }) alert: ElementRef;
  @ViewChild('alertExito', { static: true }) alertExito: ElementRef;
  ocultar = false;
  constructor(
    public dialog: MatDialog,
    private adminSrv: AdminService,
    public api: ApiService
  ) {}

  ngOnInit() {
    this.discount.mayorista = false;
    this.discount.minorista = false;
    this.discount.enabled = true;
  }
  cargarDesc() {
    this.adminSrv.createDiscount(this.adminSrv.token, this.discount).subscribe(
      (data) => {
        console.log(data);
        this.showAlertExito();
      },
      (error) => {
        console.log(error);
        this.showAlert();
      }
    );
  }

  doNotWrite(e) {
    e.preventDefault();
  }

  changeTypeDiscount($event, type: string) {
    switch (type) {
      case 'min':
        this.discount.minorista = !this.discount.minorista;
        break;

      case 'may':
        this.discount.mayorista = !this.discount.mayorista;
        break;
    }
  }

  generateRandomCode() {
    let id: string = uuid.v4();
    this.discount.code = id.substr(0, 6).toUpperCase();
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

  showAlert() {
    this.alert.nativeElement.classList.remove('d-none');
    this.alert.nativeElement.classList.add('show');
  }

  closeAlertExito() {
    this.alertExito.nativeElement.classList.remove('show');
    this.alertExito.nativeElement.classList.add('d-none');
  }

  showAlertExito() {
    this.alertExito.nativeElement.classList.remove('d-none');
    this.alertExito.nativeElement.classList.add('show');
  }
}
