import { ApiService } from './api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private api: ApiService) {}

  private getResponse(endpoint: string, params?: string) {
    return this.api.get(endpoint, params);
  }

  private postResponse(endpoint: string, contenido: any, params?: string) {
    return this.api.post(endpoint, contenido, params);
  }

  getProduct(idProduct: number) {
    return this.getResponse('productDetail/' + idProduct);
  }

  getProductBySection(idSection: number) {
    return this.getResponse('prodBySection/' + idSection);
  }

  getAllProducts() {
    return this.getResponse('allProducts');
  }

  getAllActiveProducts() {
    return this.getResponse('allActiveProducts');
  }

  getProductBySubsection(idSubsection: number) {
    return this.getResponse('prodBySubsection/' + idSubsection);
  }

  getSections() {
    return this.getResponse('sections');
  }

  getMarcas() {
    return this.getResponse('marcas');
  }

  getProductByMarca(idMarca: number) {
    return this.getResponse('productMarca/' + idMarca);
  }

  getGenerales() {
    return this.getResponse('getGenerales');
  }
}
