import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { userModel } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  token: string;
  private auth: boolean = false;

  userInfo: userModel;

  constructor(private api: ApiService) {}

  private postResponse(endpoint: string, contenido: any, params?: string) {
    return this.api.post(endpoint, contenido, params);
  }

  login(login: any) {
    return this.postResponse('login', login);
  }

  setToken(token: string) {
    this.token = token;
  }

  setAuth() {
    this.auth = true;
  }

  isAuth(): boolean {
    return this.auth;
  }

  logout() {
    console.log('entro al logout');
    localStorage.removeItem('token');
    this.auth = false;
  }

  logoutAdmin() {
    console.log('entro al logout');
    localStorage.removeItem('tokenAdmin');
    this.auth = false;
  }

  reauth(token: string) {
    const tokenModel = {
      api_token: token,
    };
    return this.postResponse('reauth', tokenModel);
  }

  setUserInfo(user: any) {
    this.userInfo = user;
  }
}
