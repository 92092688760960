import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  constructor(private api: ApiService) {}

  private postResponse(endpoint: string, contenido: any, params?: string) {
    return this.api.post(endpoint, contenido, params);
  }

  trabajaNosotros(contenido: any) {
    return this.postResponse('mails/mailTrabajaNosotros', contenido);
  }

  consulta(contenido: any) {
    return this.postResponse('mails/mailConsulta', contenido);
  }
}
