import { campaignModel } from './../../../models/campaign.model';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import {
  MdbTablePaginationComponent,
  MdbTableDirective,
} from 'angular-bootstrap-md';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-mail-campaign-dashboard',
  templateUrl: './admin-mail-campaign-dashboard.component.html',
  styleUrls: ['./admin-mail-campaign-dashboard.component.scss'],
})
export class AdminMailCampaignDashboardComponent implements OnInit {
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true })
  mdbTablePagination: MdbTablePaginationComponent;

  headElements = [
    { name: 'id', header: 'ID' },
    { name: 'name', header: 'Asunto' },
    { name: 'sendDate', header: 'Fecha de envio' },
    { name: 'sent', header: 'Enviado' },
  ];

  searchText: string = '';
  previous: string;

  lista_camp: campaignModel[] = [];

  constructor(
    private adminSrv: AdminService,
    private authSrv: AuthService,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) {}

  @HostListener('input') oninput() {
    this.searchItems();
  }

  ngOnInit() {
    this.adminSrv.reauth(localStorage.getItem('tokenAdmin')).subscribe(
      (data) => {
        this.adminSrv.setAuth();
        this.adminSrv.setToken(localStorage.getItem('tokenAdmin'));

        this.getListado();
      },
      (error) => {
        this.router.onSameUrlNavigation = 'reload';
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate(['/admin']);
      }
    );
  }

  getListado() {
    this.adminSrv.getCampaigns(this.adminSrv.token).subscribe((data) => {
      this.lista_camp = data.data;

      this.mdbTable.setDataSource(this.lista_camp);
      this.lista_camp = this.mdbTable.getDataSource();
      this.previous = this.mdbTable.getDataSource();
      this.mdbTablePagination.setMaxVisibleItemsNumberTo(8);

      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
      this.cdRef.detectChanges();
    });
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.lista_camp = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.lista_camp = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }

  irDetalle(id: number) {
    this.router.navigate(['/admin/campaignsmaker', { id: id }]);
  }

  cancelarEnvio(id: number) {
    this.adminSrv.cancelCampaign(this.adminSrv.token, id).subscribe((data) => {
      this.getListado();
    });
  }
}
