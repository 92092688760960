import { MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-admin-modelo-mail',
  templateUrl: './admin-modelo-mail.component.html',
  styleUrls: ['./admin-modelo-mail.component.scss']
})
export class AdminModeloMailComponent implements OnInit {
  contenidoMail: any;
  imagenAdj: any;
  imagenExistente: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.contenidoMail = this.data.email;
    this.imagenAdj = this.data.adjunto;
    this.imagenExistente = this.data.existente;
    console.log(this.data.email, this.imagenAdj, this.imagenExistente);
  }
}
