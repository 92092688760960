import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private api: ApiService) {}
  private getResponse(endpoint: string, params?: string) {
    return this.api.get(endpoint, params);
  }

  private postResponse(endpoint: string, contenido: any, params?: string) {
    return this.api.post(endpoint, contenido, params);
  }

  getUserData(idUser: number) {
    return this.getResponse('user/info/' + idUser);
  }

  registerUser(newUser: any) {
    return this.postResponse('register', newUser);
  }

  updateProfile(editUser: any) {
    return this.postResponse('user/completarPerfil', editUser);
  }

  createNewAddress(newAddress: any, idUser: number) {
    return this.postResponse('user/crearDomicilio/' + idUser, newAddress);
  }

  editUserAddress(address: any) {
    return this.postResponse('user/editDomicilio', address);
  }

  reset(email: any) {
    return this.postResponse('user/reset', email);
  }

  changePassword(nueva_contra: any) {
    return this.postResponse('user/changePassword', nueva_contra);
  }
}
