import { Component, OnInit } from '@angular/core';
import { generalModel } from './../models/general.model';
import { ApiService } from '../services/api.service';
import { ProductsService } from '../services/products.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  generales: generalModel;

  constructor(public api: ApiService, private productSrv: ProductsService) {
    this.getGenerales();
  }

  ngOnInit() {}

  getGenerales() {
    this.productSrv.getGenerales().subscribe(
      (data) => {
        this.generales = data.data;
      },
      (error) => {}
    );
  }
}
