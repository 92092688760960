import { MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-registro-exito',
  templateUrl: './registro-exito.component.html',
  styleUrls: ['./registro-exito.component.scss']
})
export class RegistroExitoComponent implements OnInit {
  mensaje = '';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.mensaje = this.data.mensaje;
  }
}
