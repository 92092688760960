import { UserService } from './../../services/user.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  email = '';
  @ViewChild('alert', { static: true }) alert: ElementRef;
  @ViewChild('loader', { static: true }) loader: ElementRef;

  constructor(private userSrv: UserService) {}

  ngOnInit() {}

  cambio_password() {
    this.showLoader();
    const cambio = { email: this.email };
    this.userSrv.reset(cambio).subscribe(
      data => {
        this.hideLoader();
        this.showAlert();
      },
      error => {
        this.hideLoader();
      }
    );
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

  showAlert() {
    this.alert.nativeElement.classList.add('show');
    this.alert.nativeElement.classList.remove('d-none');
  }

  showLoader() {
    this.loader.nativeElement.classList.remove('d-none');
  }

  hideLoader() {
    this.loader.nativeElement.classList.add('d-none');
  }
}
