import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { generalModel } from '../models/general.model';
import { MailService } from '../services/mail.service';
import { ProductsService } from '../services/products.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss'],
})
export class ContactoComponent implements OnInit {
  nombre: string = '';
  email: string = '';
  telefono: string = '';
  mensaje: string = '';
  generales: generalModel = {} as generalModel;
  @ViewChild('alertExito', { static: true }) alertExito: ElementRef;
  @ViewChild('loader', { static: true }) loader: ElementRef;
  constructor(
    private mailSrv: MailService,
    private productSrv: ProductsService
  ) {}

  ngOnInit() {
    this.getGenerales();
  }

  enviarConsulta() {
    this.showLoader();
    let mail = {
      name: this.nombre,
      email: this.email,
      texto: this.mensaje,
      phone: this.telefono,
    };
    this.mailSrv.consulta(mail).subscribe(
      (data) => {
        this.hideLoader();
        console.log(data);
        this.showAlertExito();
      },
      (error) => {
        this.hideLoader();
        console.log(error);
      }
    );
  }

  getGenerales() {
    this.productSrv.getGenerales().subscribe(
      (data) => {
        this.generales = data.data;
      },
      (error) => {}
    );
  }

  showAlertExito() {
    this.alertExito.nativeElement.classList.add('show');
  }

  closeAlertExito() {
    this.alertExito.nativeElement.classList.remove('show');
  }

  showLoader() {
    this.loader.nativeElement.classList.remove('d-none');
  }

  hideLoader() {
    this.loader.nativeElement.classList.add('d-none');
  }
}
