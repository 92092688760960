import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AdminService } from 'src/app/services/admin.service';
import { OrdersService } from 'src/app/services/orders.service';
import { shipmentModel } from 'src/app/models/order.model';
import {
  MdbTablePaginationComponent,
  MdbTableDirective,
} from 'angular-bootstrap-md';

@Component({
  selector: 'app-admin-envios',
  templateUrl: './admin-envios.component.html',
  styleUrls: ['./admin-envios.component.scss'],
})
export class AdminEnviosComponent implements OnInit {
  shipments: shipmentModel[] = [];

  logueado: boolean = false;
  @ViewChild('alert', { static: true }) alert: ElementRef;
  @ViewChild('alertExito', { static: true }) alertExito: ElementRef;
  @ViewChild(MdbTablePaginationComponent, { static: true })
  mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  elements: any = [];
  previous: any = [];
  headElements = ['Tipo de envio', 'Activo', 'Precio', 'Acción'];
  constructor(
    private ordersSrv: OrdersService,
    private adminSrv: AdminService,
    public api: ApiService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.adminSrv.reauth(localStorage.getItem('tokenAdmin')).subscribe(
      (data) => {
        this.adminSrv.setAuth();
        this.adminSrv.setToken(localStorage.getItem('tokenAdmin'));

        this.getEnvios();
      },
      (error) => {
        this.router.onSameUrlNavigation = 'reload';
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate(['/admin']);
      }
    );
  }

  getEnvios() {
    this.ordersSrv.getShipment().subscribe(
      (data) => {
        this.shipments = data.shipment;
        this.mdbTable.setDataSource(this.shipments);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(5);

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  editar(id: number) {
    this.router.navigate(['/admin/nuevoEnvio', { id: id }]);
  }
}
