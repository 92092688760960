import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  OnDestroy,
  EventEmitter,
  Output
} from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { ProductsService } from 'src/app/services/products.service';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ApiService } from 'src/app/services/api.service';
import { CarouselImagesModel } from 'src/app/models/products.model';
import { Subscription, Observable } from 'rxjs';

@Component({
  selector: 'app-carousel-description',
  templateUrl: './carousel-description.html',
  styleUrls: ['./carousel-description.scss'],
})
export class CarouselDescriptionComponent implements OnInit, OnDestroy {
  @Input()
  public description: string;

  @Output()
  private updateDescriptionSuccessEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private adminSrv: AdminService,
    private productSrv: ProductsService,
    private router: Router,
    private httpClient: HttpClient,
    private api: ApiService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  updateDescription() {
    this.httpClient
      .put(this.api.url + '/admin/dashboard/carrousel/description', {
        description: this.description,
      })
      .subscribe(
        (res) => {
          this.showAlert('success');
        },
        (err) => {
          this.showAlert('error');
        }
      );
  }

  showAlert(type) {
    this.updateDescriptionSuccessEvent.next(type);
  }
}
