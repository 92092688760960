import { AdminNuevoProductoDetailComponent } from './../admin-nuevo-producto-detail/admin-nuevo-producto-detail.component';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { ProductsService } from 'src/app/services/products.service';
import { singleProductResponseModel } from 'src/app/models/products.model';
import {
  MdbTablePaginationComponent,
  MdbTableDirective,
} from 'angular-bootstrap-md';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin-products-details',
  templateUrl: './admin-products-details.component.html',
  styleUrls: ['./admin-products-details.component.scss'],
})
export class AdminProductsDetailsComponent implements OnInit {
  data: any;
  logueado: boolean = false;
  @ViewChild('alert', { static: true }) alert: ElementRef;
  @ViewChild('alertExito', { static: true }) alertExito: ElementRef;
  @ViewChild(MdbTablePaginationComponent, { static: true })
  mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  elements: any = [];
  previous: any = [];
  headElements = ['Talle', 'Cantidad', 'Acción'];

  productReponse: singleProductResponseModel = {} as singleProductResponseModel;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private adminSrv: AdminService,
    private productSrv: ProductsService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private authSrv: AuthService
  ) {}

  ngOnInit() {
    this.data = this.route.snapshot.paramMap.get('id');
    this.adminSrv.reauth(localStorage.getItem('tokenAdmin')).subscribe(
      (data) => {
        console.log('user', data);
        this.adminSrv.setAuth();
        this.adminSrv.setToken(localStorage.getItem('tokenAdmin'));
        this.adminSrv.setUserInfo(data.user);
        this.logueado = this.adminSrv.isAuth();
        this.getProduct();
      },
      (error) => {
        this.logueado = false;
      }
    );
  }

  getProduct() {
    this.productSrv.getProduct(this.data).subscribe(
      (data: singleProductResponseModel) => {
        data.details.sort((a, b) => {
          return Number(a.size) - Number(b.size);
        });
        this.productReponse = data;
        this.mdbTable.setDataSource(this.productReponse.details);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(5);

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
        console.log(this.productReponse);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  modificarProductDetails() {
    this.adminSrv
      .modificarProdDetail(this.adminSrv.token, {
        productos: this.productReponse.details,
      })
      .subscribe(
        (data) => {
          console.log(data);
          this.showAlertExito();
        },
        (error) => {
          console.log(error);
          this.showAlert();
        }
      );
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

  showAlert() {
    this.alert.nativeElement.classList.remove('d-none');
    this.alert.nativeElement.classList.add('show');
  }

  closeAlertExito() {
    this.alertExito.nativeElement.classList.remove('show');
    this.alertExito.nativeElement.classList.add('d-none');
  }

  showAlertExito() {
    this.alertExito.nativeElement.classList.remove('d-none');
    this.alertExito.nativeElement.classList.add('show');
  }

  agregarProductDetail() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '40vw';
    dialogConfig.panelClass = 'productoModal';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = { producto: this.productReponse.product };
    const dialogRef = this.dialog.open(
      AdminNuevoProductoDetailComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result.conDatos) {
        this.getProduct();
      }
    });
  }

  eliminarProd(id: number) {
    this.adminSrv.deleteProdDetail(this.adminSrv.token, id).subscribe(
      (data) => {
        this.getProduct();
      },
      (error) => {}
    );
  }

  reactivarProd(id: number) {
    this.adminSrv.activarProdDetail(this.adminSrv.token, id).subscribe(
      (data) => {
        this.getProduct();
      },
      (error) => {}
    );
  }

  cerrarSesion() {
    this.authSrv.logout();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin']);
  }
}
