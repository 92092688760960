import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { shipmentModel } from 'src/app/models/order.model';
import { OrdersService } from 'src/app/services/orders.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-admin-nuevo-envio',
  templateUrl: './admin-nuevo-envio.component.html',
  styleUrls: ['./admin-nuevo-envio.component.scss'],
})
export class AdminNuevoEnvioComponent implements OnInit {
  shipment: shipmentModel = {} as shipmentModel;
  data: any;
  @ViewChild('alert', { static: true }) alert: ElementRef;
  @ViewChild('alertExito', { static: true }) alertExito: ElementRef;
  ocultar = false;
  constructor(
    private route: ActivatedRoute,
    private authSrv: AuthService,
    private ordersSrv: OrdersService,
    private adminSrv: AdminService,
    public api: ApiService
  ) {}

  ngOnInit() {
    this.data = this.route.snapshot.paramMap.get('id');
    this.getEnvioInfo(this.data);
  }

  cargarEnvio() {
    this.ocultar = true;
    this.closeAlertExito();
    this.closeAlert();
    if (this.data) {
      this.guardarEdicion();
    } else {
      this.guardarNuevo();
    }
  }

  getEnvioInfo(id: number) {
    this.ordersSrv.getShipmentCost(id).subscribe(
      (data) => {
        this.shipment = data.shipment;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  guardarEdicion() {
    this.adminSrv
      .editShipmentCost(this.adminSrv.token, this.data, this.shipment)
      .subscribe(
        (data) => {
          console.log(data);
          this.showAlertExito();
        },
        (error) => {
          console.log(error);
          this.showAlert();
        }
      );
  }

  guardarNuevo() {
    this.adminSrv
      .creatShipmentCost(this.adminSrv.token, this.shipment)
      .subscribe(
        (data) => {
          console.log(data);
          this.showAlertExito();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

  showAlert() {
    this.alert.nativeElement.classList.remove('d-none');
    this.alert.nativeElement.classList.add('show');
  }

  closeAlertExito() {
    this.alertExito.nativeElement.classList.remove('show');
    this.alertExito.nativeElement.classList.add('d-none');
  }

  showAlertExito() {
    this.alertExito.nativeElement.classList.remove('d-none');
    this.alertExito.nativeElement.classList.add('show');
  }
}
