import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-domicilios',
  templateUrl: './domicilios.component.html',
  styleUrls: ['./domicilios.component.scss']
})
export class DomiciliosComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
