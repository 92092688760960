import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from 'angular-bootstrap-md';
import { listaDescuentosModel } from 'src/app/models/order.model';
import { userModel } from 'src/app/models/user.model';
import { AdminService } from 'src/app/services/admin.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-discounts-list',
  templateUrl: './discounts-list.component.html',
  styleUrls: ['./discounts-list.component.scss'],
})
export class DiscountsListComponent implements OnInit {
  discounts: listaDescuentosModel[] = [];
  logueado: boolean = false;
  @ViewChild('alert', { static: true }) alert: ElementRef;
  @ViewChild('alertExito', { static: true }) alertExito: ElementRef;
  @ViewChild(MdbTablePaginationComponent, { static: true })
  mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  elements: any = [];
  previous: any = [];
  headElements = [
    'Codigo',
    'Valor %',
    'Mayorista',
    'Minorista',
    'Habilitado',
    'Acción',
  ];
  usuarios: userModel[] = [];

  constructor(
    private adminSrv: AdminService,
    public api: ApiService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getDiscounts();
  }

  getDiscounts() {
    this.adminSrv.getAllDiscount(this.adminSrv.token).subscribe(
      (data) => {
        this.discounts = data.data;
        this.mdbTable.setDataSource(this.discounts);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(5);

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  tipoEnvios(id: number): string {
    return this.discounts.filter((item) => item.id === id)[0].code;
  }

  enable(id: number) {
    this.adminSrv.changeEnableDiscount(this.adminSrv.token, id).subscribe(
      (data) => {
        this.getDiscounts();
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
