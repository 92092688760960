import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { userModel } from '../models/user.model';
@Injectable({
  providedIn: 'root',
})
export class AdminService {
  token: string;
  private auth: boolean = false;

  userInfo: userModel;
  constructor(private api: ApiService) {}

  private getResponse(endpoint: string, params?: string) {
    return this.api.get('admin/' + endpoint, params);
  }

  private postResponse(endpoint: string, contenido: any, params?: string) {
    return this.api.post('admin/' + endpoint, contenido, params);
  }

  //Logueo de Admin

  login(login: any) {
    return this.postResponse('login', login);
  }

  setToken(token: string) {
    this.token = token;
  }

  setAuth() {
    this.auth = true;
  }

  isAuth(): boolean {
    return this.auth;
  }

  catalogo(contenido: any, token: string) {
    return this.api.post('catalogo/' + token, contenido, null);
  }

  logout() {
    console.log('entro al logout');
    localStorage.removeItem('tokenAdmin');
    this.auth = false;
  }

  reauth(token: string) {
    const tokenModel = {
      api_token: token,
    };
    return this.postResponse('reauth', tokenModel);
  }

  setUserInfo(user: any) {
    this.userInfo = user;
  }

  //Modificacion de Productos

  modificarProd(token: string, producto: any) {
    return this.postResponse('modifyProd/' + token, producto);
  }

  modificarProdDetail(token: string, productos: any) {
    return this.postResponse('modifyProdDetail/' + token, productos);
  }

  deleteProd(token: string, id: number) {
    return this.postResponse('deleteProd/' + token + '/' + id, []);
  }

  deleteProductPermanently(token: string, id: number) {
    return this.postResponse('deleteProdPermanently/' + token + '/' + id, []);
  }

  deletePhoto(token: string, foto: any) {
    return this.postResponse('deleteFoto/' + token, foto);
  }

  deleteProdDetail(token: string, id: number) {
    return this.postResponse('deleteProdDetail/' + token + '/' + id, []);
  }

  activarProd(token: string, id: number) {
    return this.postResponse('activateProd/' + token + '/' + id, []);
  }

  activarProdDetail(token: string, id: number) {
    return this.postResponse('activateProdDetail/' + token + '/' + id, []);
  }

  asignarSubsecciones(token: string, idProd: number, subsecciones: any) {
    return this.postResponse(
      'asignarSubsecciones/' + token + '/' + idProd,
      subsecciones
    );
  }

  //Crear Prods

  newProductDetail(token: string, idProd: number, prodDetail: any) {
    return this.postResponse(
      'newProductDetail/' + token + '/' + idProd,
      prodDetail
    );
  }

  newProduct(token: string, prodDetail: any) {
    return this.postResponse('newProduct/' + token, prodDetail);
  }

  newPicture(token: string, idProd: number, nombre: string, prodDetail: any) {
    return this.postResponse(
      'newPicture/' + token + '/' + idProd + '/' + nombre,
      prodDetail
    );
  }

  ordenarFotos(token: string, prodDetail: any) {
    return this.postResponse('guardarOrden/' + token, prodDetail);
  }

  //Listado de usuarios

  listadoUsuarios(token: string) {
    return this.getResponse('usuarios');
  }

  getUsuario(token: string, id: number) {
    return this.getResponse('usuarios/' + id);
  }

  //Listado de Ordener

  listadoOrdenes(token: string) {
    return this.getResponse('orders/' + token);
  }

  getOrden(token: string, id: number) {
    return this.getResponse('orders/' + id + '/' + token);
  }

  updateOrder(token: string, id: number, orderUpdate: any) {
    return this.postResponse('updateOrder/' + id + '/' + token, orderUpdate);
  }

  marcarOrdenLeida(token: string, id: number) {
    return this.postResponse('orders/marcarLeido/' + id + '/' + token, null);
  }

  //Campañas de email

  getCampaigns(token: string) {
    return this.getResponse('campaigns/' + token);
  }

  getDetCampaign(token: string, id: number) {
    return this.getResponse('campaignDet/' + id + '/' + token);
  }

  newCampaign(token: string, newcamp: any) {
    return this.postResponse('newCampaign/' + token, newcamp);
  }
  cancelCampaign(token: string, id: number) {
    return this.getResponse('cancelCampaign/' + id + '/' + token);
  }
  modifyCampaign(token: string, modcamp: any) {
    return this.postResponse('modifyCampaign/' + token, modcamp);
  }

  deletePhotoCampaign(id: number, token: string) {
    return this.postResponse('campaigns/deleteFoto/' + id + '/' + token, []);
  }

  newPictureCampaign(token: string, id: number, nombre: string) {
    return this.postResponse(
      'campaigns/uploadFoto/' + id + '/' + nombre + '/' + token,
      []
    );
  }

  //Modificar montos

  modificarGral(contenido: any) {
    return this.postResponse('modificarGral', contenido);
  }

  //Cambiar banner

  cambiarBanner(token: string, banner: any) {
    return this.postResponse('modificarBanner/' + token, banner);
  }

  //Shipment costs

  creatShipmentCost(token: string, shipment: any) {
    return this.postResponse('creatShipmentCost/' + token, shipment);
  }

  editShipmentCost(token: string, id: number, shipment: any) {
    return this.postResponse('editShipmentCost/' + token + '/' + id, shipment);
  }

  deleteShipmentCost(token: string, id: number, shipment: any) {
    return this.postResponse(
      'deleteShipmentCost/' + token + '/' + id,
      shipment
    );
  }

  //Discounts
  createDiscount(token: string, shipment: any) {
    return this.postResponse('createDiscount/' + token, shipment);
  }

  changeEnableDiscount(token: string, id: number) {
    return this.postResponse('changeEnableDiscount/' + token + '/' + id, null);
  }

  getAllDiscount(token: string) {
    return this.getResponse('getAllDiscount/' + token);
  }

  getDiscountByOrder(token: string, id: number) {
    return this.getResponse('getDiscountByOrder/' + token + '/' + id);
  }
}
