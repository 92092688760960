import { Subject } from 'rxjs';
import { generalModel } from './../../models/general.model';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { ProductsService } from 'src/app/services/products.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-admin-general',
  templateUrl: './admin-general.component.html',
  styleUrls: ['./admin-general.component.scss'],
})
export class AdminGeneralComponent implements OnInit {
  generales: generalModel = {} as generalModel;
  @ViewChild('alert', { static: true }) alert: ElementRef;
  @ViewChild('alertExito', { static: true }) alertExito: ElementRef;
  @ViewChild('alertIMG', { static: true }) alertIMG: ElementRef;
  @ViewChild('alertExitoIMG', { static: true }) alertExitoIMG: ElementRef;

  public uploader: FileUploader = new FileUploader({
    queueLimit: 1,
  });

  public uploaderCarousel: FileUploader = new FileUploader({
    queueLimit: 5,
  });

  public hasBaseDropZoneOver: boolean = false;

  ocultar = false;

  eventsSubject: Subject<void> = new Subject<void>();

  carousel: any;

  constructor(
    private adminSrv: AdminService,
    private productSrv: ProductsService,
    private router: Router,
    private httpClient: HttpClient,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.adminSrv.reauth(localStorage.getItem('tokenAdmin')).subscribe(
      (data) => {
        console.log('user', data);
        this.adminSrv.setAuth();
        this.adminSrv.setToken(localStorage.getItem('tokenAdmin'));
        this.adminSrv.setUserInfo(data.user);
        this.getGenerales();
        this.loadCarouselInformation();
      },
      (error) => {
        this.router.onSameUrlNavigation = 'reload';
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate(['/admin']);
      }
    );
  }

  getGenerales() {
    this.productSrv.getGenerales().subscribe(
      (data) => {
        this.generales = data.data;
        this.generales.inactivoDesde = this.generales.inactivoDesde.substr(
          0,
          10
        );
        this.generales.inactivoHasta = this.generales.inactivoHasta.substr(
          0,
          10
        );
      },
      (error) => {}
    );
  }

  modificar() {
    this.closeAlert();
    this.closeAlertExito();
    this.adminSrv.modificarGral(this.generales).subscribe(
      (data) => {
        this.showAlertExito();
      },
      (error) => {
        this.showAlert();
      }
    );
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

  showAlert() {
    this.alert.nativeElement.classList.remove('d-none');
    this.alert.nativeElement.classList.add('show');
  }

  closeAlertExito() {
    this.alertExito.nativeElement.classList.remove('show');
    this.alertExito.nativeElement.classList.add('d-none');
  }

  showAlertExito() {
    this.alertExito.nativeElement.classList.remove('d-none');
    this.alertExito.nativeElement.classList.add('show');
  }

  closeAlertIMG() {
    this.alertIMG.nativeElement.classList.remove('show');
    this.alertIMG.nativeElement.classList.add('d-none');
  }

  showAlertIMG() {
    this.alertIMG.nativeElement.classList.remove('d-none');
    this.alertIMG.nativeElement.classList.add('show');
  }

  closeAlertExitoIMG() {
    this.alertExitoIMG.nativeElement.classList.remove('show');
    this.alertExitoIMG.nativeElement.classList.add('d-none');
  }

  showAlertExitoIMG() {
    this.alertExitoIMG.nativeElement.classList.remove('d-none');
    this.alertExitoIMG.nativeElement.classList.add('show');
  }

  //BANNER

  getFiles(): any {
    //recupero los items cargados en el input file
    return this.uploader.queue.map((fileItem) => {
      return fileItem._file;
    });
  }

  fileOverBase(ev): void {
    this.hasBaseDropZoneOver = ev;
  }

  eliminarArchivo(item: any) {
    this.uploader.queue.splice(this.uploader.queue.indexOf(item), 1);
  }

  removeCarouselFile(item: any) {
    this.uploaderCarousel.queue.splice(
      this.uploaderCarousel.queue.indexOf(item),
      1
    );
  }

  guardarImagenesNuevas() {
    this.closeAlertExitoIMG();
    this.closeAlertIMG();
    this.ocultar = true;
    let archivos = this.getFiles();

    if (this.uploader.queue.length > 0) {
      const formData = new FormData();
      formData.append('file', archivos[0]);

      let params = this.adminSrv.token;

      return this.httpClient
        .post<any>(this.api.url + '/admin/modificarBanner/' + params, formData)
        .subscribe(
          (res) => {
            console.log(res);
            this.ocultar = false;
            this.showAlertExitoIMG();
          },
          (err) => {
            console.log(err);
            this.ocultar = false;
            this.showAlertIMG();
            return err;
          }
        );
    }
  }

  removeFileCarousel(item: any) {
    this.uploaderCarousel.queue.splice(
      this.uploaderCarousel.queue.indexOf(item),
      1
    );
  }

  saveCarousel() {
    this.closeAlertExitoIMG();
    this.closeAlertIMG();
    this.ocultar = true;
    let archivos = this.uploaderCarousel.queue.map((fileItem) => {
      return fileItem._file;
    });

    if (this.uploaderCarousel.queue.length > 0) {
      archivos.forEach((element) => {
        const formData = new FormData();
        formData.append('file', element);
        const token = this.adminSrv.token;

        return this.httpClient
          .post<any>(
            this.api.url + '/admin/dashboard/carrousel/' + token,
            formData
          )
          .subscribe(
            (res) => {
              console.log(res);
              this.ocultar = false;
              this.showAlertExitoIMG();
              this.removeFileCarousel(element);
              this.eventsSubject.next();
            },
            (err) => {
              console.log(err);
              this.ocultar = false;
              this.showAlertIMG();
              return err;
            }
          );
      });
    }
  }

  changeTypeSale($event, type: string) {
    switch (type) {
      case 'min':
        this.generales.minoristaEnabled = !this.generales.minoristaEnabled;
        break;

      case 'may':
        this.generales.mayoristaEnabled = !this.generales.mayoristaEnabled;
        break;
    }
  }

  showAlertByType(type) {
    type === 'success' ? this.showAlertExito() : this.showAlert();
  }

  loadCarouselInformation() {
    return this.httpClient
      .get<any>(this.api.url + '/dashboard/carrousel')
      .subscribe(
        (res) => {
          this.carousel = res;
        },
        (err) => {
          console.log(err);
          return err;
        }
      );
  }
}
