import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthService } from './../services/auth.service';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ProductsService } from '../services/products.service';
import {
  singleProductResponseModel,
  productResponseModel,
} from '../models/products.model';
import { generalModel } from '../models/general.model';
import { ApiService } from '../services/api.service';
import { CartService } from '../services/cart.service';
import { cartDetailsModel, cartResponseModel } from '../models/cart.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NguCarouselConfig } from '@ngu/carousel';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-producto-detalle',
  templateUrl: './producto-detalle.component.html',
  styleUrls: ['./producto-detalle.component.scss'],
})
export class ProductoDetalleComponent implements OnInit {
  logueado: boolean = false;

  productReponse: singleProductResponseModel;
  talles: string[] = [];
  colores: string[] = [];

  tallesCant = [];
  tallesSelect: string;
  colorSelect: string;
  cantidad: string;
  data: any;
  carritoActivo: cartResponseModel = {} as cartResponseModel;

  hoy = new Date();

  email: string = '';

  @ViewChild('alert', { static: true }) alert: ElementRef;
  @ViewChild('alertExito', { static: true }) alertExito: ElementRef;
  @ViewChild('alertError', { static: true }) alertError: ElementRef;

  generales: generalModel;

  constructor(
    public authSrv: AuthService,
    private productSrv: ProductsService,
    public api: ApiService,
    private cartSrv: CartService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  productReponseRel: productResponseModel[] = [];

  public carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 2, md: 3, lg: 3, all: 0 },
    slide: 3,
    speed: 250,
    point: {
      visible: false,
    },
    load: 2,
    velocity: 0,
    loop: true,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)',
  };

  ngOnInit() {
    this.getGenerales();
    this.data = this.route.snapshot.paramMap.get('id');
    this.authSrv.reauth(localStorage.getItem('token')).subscribe(
      (data) => {
        this.authSrv.setAuth();
        this.authSrv.setToken(localStorage.getItem('token'));
        this.authSrv.setUserInfo(data.user);
        this.logueado = this.authSrv.isAuth();
        this.email = this.authSrv.userInfo.email;
      },
      (error) => {
        this.logueado = false;
      }
    );
    this.getProduct();
    this.getProductsRelated();
  }
  getProduct() {
    this.productSrv.getProduct(this.data).subscribe(
      (data: singleProductResponseModel) => {
        data.details.sort((a, b) => {
          return Number(a.size) - Number(b.size);
        });
        this.productReponse = data;
        this.armarListaTalles();
        //this.armarListaColores();
        if (this.authSrv.userInfo != null) {
          this.getCart();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getProductsRelated() {
    this.productSrv.getProductBySection(1).subscribe(
      (data) => {
        this.productReponseRel = data.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  armarListaTalles() {
    this.talles = [];
    for (let index = 0; index < this.productReponse.details.length; index++) {
      const element = this.productReponse.details[index];
      if (
        this.talles.indexOf(element.size.toString()) < 0 &&
        element.active == true
      ) {
        this.talles.push(element.size.toString());
      }
    }
  }

  armarListaColores() {
    this.colores = [];
    for (let index = 0; index < this.productReponse.details.length; index++) {
      const element = this.productReponse.details[index];
      if (this.colores.indexOf(element.color.toString()) < 0) {
        this.colores.push(element.color.toString());
      }
    }
  }

  seleccionarTalle(talle: string) {
    let item = this.productReponse.details.filter((item) => {
      return item.size == talle;
    });

    if (item[0]) {
      let itemTalle = this.tallesCant.filter((item) => {
        return item.talle == talle;
      });

      if (!itemTalle[0]) {
        this.tallesCant.push({
          id: item[0].id,
          talle: item[0].size,
          cantidad: 0,
          stock: item[0].quantity,
        });
      } else {
        this.tallesCant.splice(this.tallesCant.indexOf(itemTalle[0]), 1);
      }
    }
  }

  existeTalle(talle: string): boolean {
    let item = this.tallesCant.filter((item) => {
      return item.talle == talle;
    });
    if (item[0]) {
      return true;
    } else {
      return false;
    }
  }

  seleccionarColor(color: string) {
    if (this.colorSelect == color) {
      this.colorSelect = null;
    } else {
      this.colorSelect = color;
    }
  }

  verificarColor(color: string): boolean {
    let item = this.productReponse.details.filter((item) => {
      return item.color == color && item.size == this.tallesSelect;
    });
    if (item[0]) {
      return true;
    } else {
      return false;
    }
  }

  verificarTalle(talle: string): boolean {
    let item = this.productReponse.details.filter((item) => {
      return item.color == this.colorSelect && item.size == talle;
    });
    if (item[0]) {
      return true;
    } else {
      return false;
    }
  }

  agregarItemCarrito() {
    if (this.logueado) {
      this.agregarCarrito();
    } else {
      this.Login();
    }
  }

  agregarCarrito() {
    if (this.productReponse.product.priceMin == 0) {
      this.showAlertError();
    } else {
      if (this.tallesCant.length < 1) {
        this.showAlert();
      } else {
        for (let index = 0; index < this.tallesCant.length; index++) {
          const element = this.tallesCant[index];
          if (Number(element.cantidad) > 0) {
            let itemAdd: cartDetailsModel = {} as cartDetailsModel;

            itemAdd.idProduct = this.productReponse.product.id;
            itemAdd.quantity = Number(element.cantidad);
            itemAdd.unitPrice = this.productReponse.product.price;
            itemAdd.idProductDetail = element.id;

            this.cartSrv
              .userCartAddItem(
                this.authSrv.userInfo.id,
                itemAdd,
                Number(this.productReponse.product.outlet)
              )
              .subscribe(
                (data) => {
                  this.cartSrv.updateCartNumber(this.authSrv.userInfo.id);
                  this.showAlertExito();
                  this.getCart();
                },
                (error) => {
                  console.log(error);
                }
              );
          }
        }
      }
    }
  }

  getCart() {
    this.cartSrv
      .getCart(
        this.authSrv.userInfo.id,
        Number(this.productReponse.product.outlet)
      )
      .subscribe(
        (data) => {
          this.carritoActivo = data;
        },
        (error) => {}
      );
  }

  obtenerMaximo(idProdDetail: number, max: number): number {
    if (this.logueado) {
      let itemCarro = this.carritoActivo.cartDetails.filter((item) => {
        return item.productDetail[0].id == idProdDetail;
      });
      if (itemCarro[0]) {
        return max - itemCarro[0].cartDetail.quantity;
      } else {
        return max;
      }
    } else {
      return max;
    }
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
  }

  showAlert() {
    this.alert.nativeElement.classList.add('show');
  }

  showAlertExito() {
    this.alertExito.nativeElement.classList.add('show');
  }

  closeAlertExito() {
    this.alertExito.nativeElement.classList.remove('show');
  }

  showAlertError() {
    this.alertError.nativeElement.classList.add('show');
  }
  closeAlertError() {
    this.alertError.nativeElement.classList.remove('show');
  }

  hayStock(talle: string): boolean {
    let item = this.productReponse.details.filter((item) => {
      return item.size == talle;
    });

    if (item[0]) {
      if (item[0].quantity > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  Login() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '40vw';
    dialogConfig.panelClass = 'loginModal';
    dialogConfig.closeOnNavigation = true;
    const dialogRef = this.dialog.open(LoginComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.authSrv.reauth(localStorage.getItem('token')).subscribe((data) => {
        this.authSrv.setAuth();
        this.authSrv.setToken(localStorage.getItem('token'));
        this.logueado = this.authSrv.isAuth();
        this.authSrv.setUserInfo(data.user);
        this.cartSrv.updateCartNumber(this.authSrv.userInfo.id);
        this.router.onSameUrlNavigation = 'reload';
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([this.router.url]);
      });
    });
  }

  irProducto(id: number) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/product_detalle', { id: id }]);

    /* const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { id: id };
    dialogConfig.minWidth = '50vw';
    this.dialog.open(ProductoDetalleComponent, dialogConfig); */
  }

  chequearFecha(fecha: string): boolean {
    if (fecha) {
      let fechaDate = new Date(fecha.slice(0, 10) + 'T03:00');
      if (this.hoy.getTime() >= fechaDate.getTime()) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  cambiarFecha(fecha: string): string {
    let fechaDate = new Date(fecha.slice(0, 10) + 'T03:00');

    let mes =
      Number(fechaDate.getMonth() + 1).toString().length < 2
        ? '0' + Number(fechaDate.getMonth() + 1).toString()
        : Number(fechaDate.getMonth() + 1).toString();

    let dia =
      fechaDate.getDate().toString().length < 2
        ? '0' + fechaDate.getDate().toString()
        : fechaDate.getDate().toString();

    return dia + '/' + mes + '/' + fechaDate.getFullYear().toString();
  }

  getGenerales() {
    this.productSrv.getGenerales().subscribe(
      (data) => {
        this.generales = data.data;
      },
      (error) => {}
    );
  }

  cerrarCarrito(): boolean {
    return false;
    // let desde = new Date(this.generales.inactivoDesde.slice(0, 10) + 'T00:00');
    // let hasta = new Date(this.generales.inactivoHasta.slice(0, 10) + 'T23:59');
    // let hoy = new Date();

    // if (hoy.getTime() >= desde.getTime() && hoy.getTime() <= hasta.getTime()) {
    //   if (
    //     this.authSrv.userInfo.email != 'testing@gelljeans.com.ar' &&
    //     this.authSrv.userInfo.email != 'chinty_94@hotmail.com'
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } else {
    //   return false;
    // }
  }
}
