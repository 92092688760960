import { generalModel } from './../models/general.model';
import { OrdersService } from './../services/orders.service';
import { ApiService } from './../services/api.service';
import { CartService } from './../services/cart.service';
import { AuthService } from './../services/auth.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { cartResponseModel } from '../models/cart.model';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import { userDataResponse, userModel } from '../models/user.model';
import {
  orderModel,
  ivaModel,
  orderItemModel,
  paymentModel,
  shipmentModel,
  listaDescuentosModel,
} from '../models/order.model';
import { isNullOrUndefined } from 'util';
import { ProductsService } from '../services/products.service';

@Component({
  selector: 'app-orden',
  templateUrl: './orden.component.html',
  styleUrls: ['./orden.component.scss'],
})
export class OrdenComponent implements OnInit {
  logueado = false;

  carrito: cartResponseModel;
  userData: userDataResponse = {} as userDataResponse;
  nombreEmpresa: string = '';
  iva: number = 1;
  payment: number;
  listaEnvios: shipmentModel[] = [];
  envio: number;
  costoEnvio: number;
  valoresIva: ivaModel[] = [];
  valoresPayment: paymentModel[] = [];
  linkMP: string;
  paso = 1;
  tieneOrdenPrevia: boolean = false;
  montoMin: number;
  procesandoOrden: boolean = false;
  generales: generalModel;
  discount: listaDescuentosModel = {} as listaDescuentosModel;
  discountCode = '';
  discountError = false;
  discountErrorMsg: string = 'Código Invalido';
  @ViewChild('alert', { static: true }) alert: ElementRef;
  @ViewChild('loader', { static: true }) loader: ElementRef;

  outlet: number = 0;

  orderCode: string = '';

  constructor(
    private authSrv: AuthService,
    private cartSrv: CartService,
    public api: ApiService,
    public router: Router,
    public userSrv: UserService,
    private orderSrv: OrdersService,
    private productSrv: ProductsService,
    private route: ActivatedRoute
  ) {
    this.userData.data = {} as userModel;
  }

  ngOnInit() {
    this.outlet = Number(this.route.snapshot.paramMap.get('tipo'));
    this.getGenerales();
  }

  getAuthUser() {
    this.authSrv.reauth(localStorage.getItem('token')).subscribe(
      (data) => {
        this.authSrv.setAuth();
        this.authSrv.setToken(localStorage.getItem('token'));
        this.authSrv.setUserInfo(data.user);
        this.logueado = this.authSrv.isAuth();
        this.getIva();
        this.getPayments();
        this.getShipment();
        this.getUserData();
        this.getCart();
        this.getOrder();
        if (this.authSrv.userInfo.dni == null) {
          this.router.navigate(['/cart']);
        }
      },
      (error) => {
        this.router.navigate(['/']);
      }
    );
  }

  getUserData() {
    this.userSrv.getUserData(this.authSrv.userInfo.id).subscribe(
      (data) => {
        this.userData = data;
      },
      (error) => {}
    );
  }

  getIva() {
    this.orderSrv.getIva().subscribe(
      (data) => {
        this.valoresIva = data.iva;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getPayments() {
    this.orderSrv.getPayments().subscribe(
      (data) => {
        if (this.generales.mayoristaEnabled) {
          this.valoresPayment = data.payments;
          this.valoresPayment = this.valoresPayment.filter((item) => {
            return item.id != 3;
          });
        } else {
          this.valoresPayment = [];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getShipment() {
    this.orderSrv.getShipment().subscribe(
      (data) => {
        this.listaEnvios = data.shipment.filter((item) => {
          return item.active === true;
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  filterShipmentCosts() {
    const precio = this.listaEnvios.filter((item) => {
      return item.id == this.envio;
    });
    if (precio != null) {
      this.costoEnvio = Number(precio[0].cost);
    } else {
      this.costoEnvio = undefined;
    }
    this.valorPorcentaje();
  }

  getCart() {
    this.cartSrv.getCart(this.authSrv.userInfo.id, this.outlet).subscribe(
      (data) => {
        this.carrito = data;
        if (
          this.carrito.cart.totalPrice < this.montoMin ||
          !this.generales.mayoristaEnabled
        ) {
          if (this.generales.minoristaEnabled) {
            this.payment = 3;
          }
        } else {
          this.payment = this.payment == 3 ? 1 : this.payment;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  subtotal(precio: number, cant: number): number {
    return precio * cant;
  }

  createOrder() {
    this.cartSrv
      .getCart(this.authSrv.userInfo.id, this.outlet)
      .subscribe((data) => {
        this.carrito = data;
        if (
          this.payment == 3 ||
          this.carrito.cart.totalPrice >= this.montoMin
        ) {
          this.showLoader();
          this.valorPorcentaje();
          this.createOrderPost();
        }
      });
  }

  createOrderPost() {
    var total =
      this.payment == 3
        ? this.carrito.cart.totalMinPrice
        : this.carrito.cart.totalPrice;

    let order: orderModel = {} as orderModel;
    order.idUser = this.userData.data.id;
    order.totalPrice = total;
    order.enterpriseName = this.nombreEmpresa;
    order.country = this.userData.data.country;
    order.province = this.userData.data.province;
    order.city = this.userData.data.city;
    order.postalCode = this.userData.data.postalCode;
    order.streetName = this.userData.data.streetName;
    order.number = this.userData.data.nro;
    order.floor = this.userData.data.floor;
    order.dpto = this.userData.data.dpto;
    order.iva = this.iva;
    order.idPayment = this.payment;
    order.observations = '-';
    order.status = '-';
    order.shipment =
      this.listaEnvios.find((item) => item.id == this.envio).name +
      ' (' +
      (this.costoEnvio
        ? '$' + this.costoEnvio.toString()
        : 'Acordar con vendedor') +
      ')';

    order.outlet = this.outlet == 1 ? true : false;

    order.mayor = this.payment == 3 ? false : true;

    order.discountId = this.discount.id ? this.discount.id : null;
    order.shipmentId = this.envio;

    let itemsOrder: orderItemModel[] = [];

    for (let index = 0; index < this.carrito.cartDetails.length; index++) {
      const element = this.carrito.cartDetails[index];
      let orderItem: orderItemModel = {} as orderItemModel;

      orderItem.idOrder = 0;
      orderItem.idProduct = element.cartDetail.idProduct;
      orderItem.idProductDetail = element.cartDetail.idProductDetail;
      orderItem.quantity = element.cartDetail.quantity;

      if (this.payment == 3) {
        orderItem.unitPrice = element.product.priceMin;
      } else {
        orderItem.unitPrice = element.cartDetail.unitPrice;
      }

      itemsOrder.push(orderItem);
    }

    this.orderSrv
      .crearOrden(this.authSrv.userInfo.id, {
        order: order,
        orderItems: itemsOrder,
      })
      .subscribe(
        (data) => {
          this.orderCode = data.order.code;
          if (this.payment == 3) {
            this.orderSrv.obtainLink(data.order.id).subscribe(
              (data) => {
                this.linkMP = data.mp;
                this.hideLoader();
                this.showAlert();
                this.eliminarCarroActual();
              },
              (error) => {
                this.hideLoader();
                this.showAlert();
                this.eliminarCarroActual();
              }
            );
          } else {
            this.hideLoader();
            this.showAlert();
            this.eliminarCarroActual();
          }
        },
        (error) => {
          this.hideLoader();
        }
      );
  }

  getOrder() {
    this.orderSrv.getOrders(this.authSrv.userInfo.id).subscribe((data) => {
      if (!isNullOrUndefined(data.order)) {
        this.tieneOrdenPrevia = true;
        this.montoMin = Number(this.generales.topeMinimo);
      }
    });
  }

  total(): { total: number; valorPorcentaje: number } {
    let total = 0;

    for (let index = 0; index < this.carrito.cartDetails.length; index++) {
      const element = this.carrito.cartDetails[index];
      if (this.payment == 3) {
        total += element.cartDetail.quantity * element.product.priceMin;
      } else {
        total += element.cartDetail.quantity * element.cartDetail.unitPrice;
      }
    }

    let porcentaje = this.valoresIva.filter((item) => {
      return item.id == this.iva;
    });

    let valorPorcentaje = (total * porcentaje[0].percentaje) / 100;

    total += valorPorcentaje;

    return { total: total, valorPorcentaje: valorPorcentaje };
  }

  valorPorcentaje(): number {
    let total = this.total().total;
    let valorPorcentaje = this.total().valorPorcentaje;
    if (this.discount.id != null) {
      let descuento = (total * this.discount.value) / 100;
      total -= descuento;
    }

    const envio = this.costoEnvio ? this.costoEnvio : 0;
    total += envio;

    if (this.payment == 3) {
      this.carrito.cart.totalMinPrice = total;
    } else {
      this.carrito.cart.totalPrice = total;
    }

    if (this.carrito.cart.totalPrice < this.montoMin) {
      this.payment = 3;
    }
    return valorPorcentaje;
  }

  cambioPayment() {
    if (
      this.discount != null &&
      !this.isDiscountValid(
        this.discount.enabled,
        this.discount.mayorista,
        this.discount.minorista
      )
    ) {
      this.cleanDiscount();
    } else {
      if (
        this.checkIfTotalIsBelowLimitWithDiscount(
          this.discount.mayorista,
          this.discount.value
        )
      ) {
        this.cleanDiscount();
      }
    }
    this.valorPorcentaje();
  }

  pasoSiguiente(paso: number) {
    switch (paso) {
      case 1:
        this.paso = 2;
        break;

      case 2:
        this.paso = 3;
        break;
    }
  }
  pasoAnterior(paso: number) {
    switch (paso) {
      case 2:
        this.paso = 1;
        break;

      case 3:
        this.paso = 2;
        break;
    }
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

  showAlert() {
    this.alert.nativeElement.classList.remove('d-none');
    this.alert.nativeElement.classList.add('show');
  }

  showLoader() {
    this.loader.nativeElement.classList.remove('d-none');
    this.procesandoOrden = true;
  }

  hideLoader() {
    this.loader.nativeElement.classList.add('d-none');
    this.showAlert();
  }

  eliminarCarroActual() {
    this.cartSrv.eliminarCart(this.authSrv.userInfo.id).subscribe((data) => {
      this.cartSrv.updateCartNumber(this.authSrv.userInfo.id);
    });
  }

  getGenerales() {
    this.productSrv.getGenerales().subscribe(
      (data) => {
        this.generales = data.data;
        this.montoMin = Number(this.generales.topeMaximo);

        let desde = new Date(
          this.generales.inactivoDesde.slice(0, 10) + 'T00:00'
        );
        let hasta = new Date(
          this.generales.inactivoHasta.slice(0, 10) + 'T23:59'
        );
        let hoy = new Date();

        // if (
        //   hoy.getTime() >= desde.getTime() &&
        //   hoy.getTime() <= hasta.getTime()
        // ) {
        //   this.router.navigate(['/cerrado']);
        // } else {
        //   this.getAuthUser();
        // }
      },
      (error) => {}
    );
  }

  superaMonto(monto1: number, monto2: number): boolean {
    return Number(monto1) >= Number(monto2);
  }

  checkDiscount() {
    this.orderSrv
      .searchDiscount(
        this.authSrv.token,
        this.authSrv.userInfo.id,
        this.discountCode.toUpperCase()
      )
      .subscribe(
        (data) => {
          if (data.data) {
            if (
              this.isDiscountValid(
                data.data.enabled,
                data.data.mayorista,
                data.data.minorista
              )
            ) {
              if (
                !this.checkIfTotalIsBelowLimitWithDiscount(
                  data.data.mayorista,
                  data.data.value
                )
              ) {
                this.discount = data.data;
                this.discountError = false;
                this.valorPorcentaje();
              } else {
                this.discount.id = null;
                this.discount.value = null;
                this.discountErrorMsg =
                  'El código seleccionado no es aplicable debido al mínimo de compra';
                this.discountError = true;
              }
            } else {
              this.discount.id = null;
              this.discount.value = null;
              this.discountErrorMsg = 'Código Invalido';
              this.discountError = true;
            }
          } else {
            this.discount.id = null;
            this.discount.value = null;
            this.discountErrorMsg = 'Código Invalido';
            this.discountError = true;
          }
        },
        (error) => {
          this.discount.id = null;
          this.discount.value = null;
          this.discountError = true;
        }
      );
  }

  isDiscountValid(enabled: boolean, mayorista: boolean, minorista: boolean) {
    if (this.outlet != 1) {
      if (this.payment == 3) {
        return enabled && minorista;
      } else {
        return enabled && mayorista;
      }
    }
    return false;
  }

  checkIfTotalIsBelowLimitWithDiscount(mayorista: boolean, valor: number) {
    if (mayorista) {
      var totalWithDiscount = this.total().total;
      var discount = (totalWithDiscount * valor) / 100;
      totalWithDiscount -= discount;

      return totalWithDiscount < this.montoMin ? true : false;
    }
    return false;
  }

  cleanDiscount() {
    this.discount.id = null;
    this.discount.value = null;
    this.discount.code = null;
    this.discount.enabled = null;
    this.discount.mayorista = null;
    this.discount.minorista = null;
    this.discountError = false;
    this.discountCode = '';
    this.valorPorcentaje();
  }

  allowOrder(): boolean {
    if (this.payment == 3 && this.generales.minoristaEnabled) {
      return true;
    }

    if (
      this.montoMin <= this.carrito.cart.totalPrice &&
      this.generales.mayoristaEnabled &&
      this.payment != 3
    ) {
      return true;
    }

    return false;
  }
}
