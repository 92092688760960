import { productModel } from '../../../models/products.model';
import { AdminService } from '../../../services/admin.service';
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import {
  productResponseModel,
  marcaModel,
} from 'src/app/models/products.model';
import { ApiService } from 'src/app/services/api.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AdminNuevoProductoComponent } from '../admin-nuevo-producto/admin-nuevo-producto.component';
import { Router, ActivatedRoute } from '@angular/router';
import {
  MdbTablePaginationComponent,
  MdbTableDirective,
} from 'angular-bootstrap-md';
import { AuthService } from 'src/app/services/auth.service';
import { SweetAlert } from 'sweetalert/typings/core';
const swal: SweetAlert = require('sweetalert');

@Component({
  selector: 'app-admin-products',
  templateUrl: './admin-products.component.html',
  styleUrls: ['./admin-products.component.scss'],
})
export class AdminProductsComponent implements OnInit {
  productReponse: productResponseModel[] = [];
  marcas: marcaModel[] = [];

  logueado: boolean = false;
  @ViewChild('alert', { static: true }) alert: ElementRef;
  @ViewChild('alertExito', { static: true }) alertExito: ElementRef;
  @ViewChild(MdbTablePaginationComponent, { static: true })
  mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  elements: any = [];
  previous: any = [];
  headElements = [
    'Codigo',
    'Nombre',
    'Descripción',
    'Precio x mayor',
    'Precio x menor',
    'Marca',
    'Acción',
  ];

  tipo: string = 'linea';

  constructor(
    private productSrv: ProductsService,
    private adminSrv: AdminService,
    public api: ApiService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private authSrv: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.tipo = this.route.snapshot.paramMap.get('tipo');
    this.adminSrv
      .reauth(localStorage.getItem('tokenAdmin'))
      .subscribe((data) => {
        this.adminSrv.setAuth();
        this.adminSrv.setToken(localStorage.getItem('tokenAdmin'));
        this.logueado = this.adminSrv.isAuth();
        this.getMarcas();
      });
  }

  getProducts() {
    this.productSrv.getAllProducts().subscribe(
      (data) => {
        console.log(data);
        this.productReponse = data.data;
        this.productReponse.sort((a, b) => {
          return b.product.id - a.product.id;
        });
        switch (this.tipo) {
          case 'outlet':
            this.productReponse = this.productReponse.filter((item) => {
              return item.product.outlet == true;
            });
            break;

          default:
            this.productReponse = this.productReponse.filter((item) => {
              return item.product.outlet == false;
            });
            break;
        }
        this.mdbTable.setDataSource(this.productReponse);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(5);

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getMarcas() {
    this.productSrv.getMarcas().subscribe(
      (data) => {
        console.log(data);
        this.marcas = data.data;
        this.getProducts();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getCatalogo() {
    let fecha = new Date();
    let fechaComp =
      fecha.getFullYear().toString() +
      '-' +
      (Number(fecha.getMonth() + 1).toString().length < 2
        ? '0' + Number(fecha.getMonth() + 1).toString()
        : Number(fecha.getMonth() + 1).toString()) +
      '-' +
      (fecha.getDate().toString().length < 2
        ? '0' + fecha.getDate().toString()
        : fecha.getDate().toString()) +
      ' ' +
      (fecha.getHours().toString().length < 2
        ? '0' + fecha.getHours().toString()
        : fecha.getHours().toString()) +
      ':' +
      (Number(fecha.getMinutes() + 2).toString().length < 2
        ? '0' + Number(fecha.getMinutes() + 2).toString()
        : Number(fecha.getMinutes() + 2).toString()) +
      ':00';
    let enviarCatalogo = { fecha: fechaComp };
    this.adminSrv.catalogo(enviarCatalogo, this.adminSrv.token).subscribe(
      (data) => {
        this.closeAlert();
        this.closeAlertExito();
        this.showAlertExito();
      },
      (error) => {
        this.closeAlert();
        this.closeAlertExito();
        this.showAlert();
      }
    );
  }

  nombreMarca(idMarcar: number): string {
    let marcaItem = this.marcas.filter((item) => {
      return item.id == idMarcar;
    });

    if (marcaItem[0]) {
      return marcaItem[0].name;
    } else {
      return '';
    }
  }

  editarProducto(item: productModel) {
    this.router.navigate(['/admin/nuevoProd', { id: item.id }]);
  }

  verDetalleProd(item: productModel) {
    this.router.navigate(['/admin/productsDetail', { id: item.id }]);
  }

  eliminarProd(id: number) {
    this.adminSrv.deleteProd(this.adminSrv.token, id).subscribe(
      (data) => {
        this.getProducts();
      },
      (error) => {}
    );
  }

  eliminarProdPermanente(id: number) {
    swal('¿Desea eliminar este articulo permanentemente?', {
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancelar',
          value: null,
          visible: true,
        },
        continue: {
          text: 'Continuar',
          value: 'continue',
          visible: true,
        },
      },
    }).then((value) => {
      switch (value) {
        case 'continue':
          this.adminSrv
            .deleteProductPermanently(this.adminSrv.token, id)
            .subscribe(
              (data) => {
                this.getProducts();
              },
              (error) => {}
            );
          break;
        default:
          break;
      }
    });
  }

  reactivarProd(id: number) {
    this.adminSrv.activarProd(this.adminSrv.token, id).subscribe(
      (data) => {
        this.getProducts();
      },
      (error) => {}
    );
  }

  cerrarSesion() {
    this.authSrv.logout();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin']);
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

  showAlert() {
    this.alert.nativeElement.classList.remove('d-none');
    this.alert.nativeElement.classList.add('show');
  }

  closeAlertExito() {
    this.alertExito.nativeElement.classList.remove('show');
    this.alertExito.nativeElement.classList.add('d-none');
  }

  showAlertExito() {
    this.alertExito.nativeElement.classList.remove('d-none');
    this.alertExito.nativeElement.classList.add('show');
  }
}
