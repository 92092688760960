import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(private api: ApiService) {}

  private getResponse(endpoint: string, params?: string) {
    return this.api.get(endpoint, params);
  }

  private postResponse(endpoint: string, contenido: any, params?: string) {
    return this.api.post(endpoint, contenido, params);
  }

  crearOrden(idUser: number, contenido: any) {
    return this.postResponse('order/createOrder/' + idUser, contenido);
  }

  addItemsOrden(idOrder: number, contenido: any) {
    return this.postResponse('order/addItems/' + idOrder, contenido);
  }

  getIva() {
    return this.getResponse('order/getIva');
  }

  getPayments() {
    return this.getResponse('order/getPayments');
  }

  getShipment() {
    return this.getResponse('order/getShipments');
  }

  enviarMail(idOrder: number) {
    return this.getResponse('order/enviarMail/' + idOrder);
  }

  getOrders(idUser: number) {
    return this.getResponse('order/getOrder/' + idUser);
  }

  obtainLink(idOrder: number) {
    return this.getResponse('order/pagarMP/' + idOrder);
  }

  getShipmentCosts() {
    return this.getResponse('getShipmentCosts');
  }

  getShipmentCost(id: number) {
    return this.getResponse('getShipmentCost/' + id);
  }

  searchDiscount(token: string, id: number, discount: string) {
    return this.getResponse(
      'order/searchDiscount/' + token + '/' + id + '/' + discount
    );
  }

  cancelOrder(order: any) {
    return this.postResponse('order/cancelOrder', order);
  }
}
